import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";
import SideMenu from "../DashboardPage/SideMenu";
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import MetaTags from 'react-meta-tags';

import { getUserProfileAction, providerList,specializationList ,getPTAvailabilityList,requestProviderAppointment} from "../../Actions/User.action";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "../../../_Common/toaster";
import {  Card, CardBody,Label, Form, Input, Button, FormGroup} from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import RequestPop from './RequestPop.js';
import {Modal, ModalHeader, ModalBody, Col, Row,Table } from 'reactstrap';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';


import $ from "jquery";
import Multiselect from "multiselect-react-dropdown";

const RequestForAppointment = (props) => {


  const [profileData, setProfileData] = useState({});

  const patientProfiledata = async () => {
    let patientdata = await getUserProfileAction();
    setProfileData(patientdata.data);
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const setModalIsOpenToTrue =(e,item)=>{  
    // console.log(item,"item")
     setSelectedDoc({item})
    setModalIsOpen(true)
}
const setModalIsOpenToFalse =(row)=>{
  setModalIsOpen(false)
}
  
const [dataList, setClinicList] = useState([]);
const [deleteAlert, setDeleteAlert] = useState(null);
const [deleteBlogAlert, setDeleteBlogAlert] = useState(null);
  const [name, setName] = useState('');
  const [spinner,setSpinner] = useState(false);


const getPageDataList = async()=>{
  setSpinner(true);

    let dataList = await getPTAvailabilityList();   
    console.log("dataListdataListdataList",dataList.data)     
    setClinicList(dataList.data);
    setSpinner(false);

}


useEffect(()=>{
  getPageDataList();
  patientProfiledata();
},[]);

const [searchInput, setSearchInput] = useState('');
const [filteredResults, setFilteredResults] = useState([]);

const searchItems = (searchValue) => {
  setSearchInput(searchValue)
  console.log("searchValue",searchValue)

  if (searchInput !== '') {
   console.log("dataList",dataList)
    const filteredData = dataList.filter((item) => {
        
          return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
      console.log("filteredData",filteredData)
  }
  else{
      setFilteredResults(dataList)
  }
}

  return (
    <div>
    <MetaTags className="bg-rr">
        <title>Request For Appointment</title>
        <meta name="description" content="Recover" />
        <meta name="keywords" content="Recover" />
      </MetaTags>
 <section className="dash_section pad-reggis Pt_list_sec">
 <BreadcrumbsComp bcChanges={[{path: '/RequestForAppointment', breadcrumb: 'Request Provider Availability '}]} />

       <div className="container maincontainer">
         <div className="row">
           {localStorage.getItem("jwtToken") &&
             localStorage.getItem("RecoverWebStatus") && (
               <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
                 <SideMenu />
               </div>
             )}
           <div
             className={
               localStorage.getItem("jwtToken")
                 ? "col-md-9 right_dashboard"
                 : "col-md-12 right_dashboard "
             }
           >
             <div className="content-setion scrollbar4 rightContainer">
               <div className="section_title m-25">
                 <h3>Request For Appointment</h3>

                   </div>
                  <p className="paraaa">disclaimer:&nbsp;If you would like to schedule with a provider who does not have the availability you need, Recover will contact the provider directly to request scheduling options</p> 
                 
{/* 
            <div className=''>
                <div><label className=''>Find A Provider</label></div> 
                <input icon='search'
                className='SerchBarrr'
                placeholder='Find A Provider'
                onChange={(e) => searchItems(e.target.value)}
            />
                </div> */}
                   
                   <Table responsive className="commontabl tblres">
                <thead>
                  <tr>  
                    <th>Profile </th>
                   <th>Provider Name</th>
                    <th>Specialization</th>
                    <th>Price</th>
                    <th className="action">Action</th>
                    
                  </tr>
                </thead>
                
                <tbody> 

                {searchInput.length > 1 ? (
      filteredResults&& filteredResults.map((item, index)=>(
        
          <tr >                                                       
                        <td className="text-center"> 
                          <img
                                src={`${process.env.REACT_APP_STATIC_S3_URL + item.profileImg}`}
                                alt="No Profile Pic"
                         className="plplpl"
                                onError={(e) => {
                                  e.target.src = "/static/img/providerimg.png"
                                  e.target.style = ''
                                }}
                              />   
                        </td>
                        <td className="nameid" >                   
                          {item.fullName?item.fullName:''}  
                        </td>
                        
                       
                        <td className="nameid">                   
                          {item.specialization?.map(function(ttttt, index) {
              return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
            })}  
                        </td>
                        
                        <td>
                          ${item.price_for_60_min?item.price_for_60_min:''}  (60 min)
                          <br/>${item.price_for_30_min?item.price_for_30_min:''} (30 min)
                          
                        </td>
                        <td>
                          <button type="button"className="btn btn-primary" onClick={e=>setModalIsOpenToTrue(e,item)}>Request For Appointment</button>                  
                        </td>
                       
                      </tr>
    
         ))
      ) : (
         dataList && dataList?.map((item, index)=>(
          
            <tr>                                                       
                        <td className="text-center"> 
                          <img
                                src={`${process.env.REACT_APP_STATIC_S3_URL + item.profileImg}`}
                                alt="No Profile Pic"
                         className="plplpl"
                                onError={(e) => {
                                  e.target.src = "/static/img/providerimg.png"
                                  e.target.style = ''
                                }}
                              />   
                        </td>
                        <td className="nameid" >                   
                          {item.fullName?item.fullName:''}  
                        </td>
                        
                       
                        <td className="nameid">                   
                          {item.specialization?.map(function(ttttt, index) {
              return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
            })}  
                        </td>
                        
                        <td>
                          ${item.price_for_60_min?item.price_for_60_min:''}  (60 min)
                          <br/>${item.price_for_30_min?item.price_for_30_min:''} (30 min)
                          
                        </td>
                        <td>
                          <button type="button"className="btn btn-primary" onClick={e=>setModalIsOpenToTrue(e,item)}>Request For Appointment</button>                  
                        </td>
                       
                      </tr>
          
           ))
      )}




                {/* {dataList.map(function (item,index) {  
                                     
                      return (<tr key={index}>                                                       
                        <td className="text-center"> 
                          <img
                                src={`${process.env.REACT_APP_STATIC_S3_URL + item.profileImg}`}
                                alt="No Profile Pic"
                         className="plplpl"
                                onError={(e) => {
                                  e.target.src = "/static/img/providerimg.png"
                                  e.target.style = ''
                                }}
                              />   
                        </td>
                        <td className="nameid" >                   
                          {item.fullName?item.fullName:''}  
                        </td>
                        
                       
                        <td className="nameid">                   
                          {item.specialization?.map(function(ttttt, index) {
              return <span key={`demo_snap_${index}`}>{ (index ? ', ' : '') + ttttt.specializationName }</span>;
            })}  
                        </td>
                        
                        <td>
                          ${item.price_for_60_min?item.price_for_60_min:''}  (60 min)
                          <br/>${item.price_for_30_min?item.price_for_30_min:''} (30 min)
                          
                        </td>
                        <td>
                          <button type="button"className="btn btn-primary" onClick={e=>setModalIsOpenToTrue(e,item)}>Request For Appointment</button>                  
                        </td>
                       
                      </tr>)
                  })}                 */}
                </tbody>
                
                <Modal isOpen={modalIsOpen}>
                          <div>
                            <button className='popbutton' onClick={setModalIsOpenToFalse}>Close
                            </button></div>
                
                <RequestPop
                selectedDoc={selectedDoc}
                profileData={profileData}
                />
            </Modal>
              </Table>
               </div>
             </div>
           </div>
         </div>
       </section>  
   
       <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="/static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
};
export default withLocalize(withRouter(RequestForAppointment));
