import React,{useState} from 'react';
import { withRouter, useParams } from "react-router-dom";

import { withLocalize } from 'react-localize-redux';

import NotesTemplatePage from './NotesTemplate';

const NotesDetailPage = ({history, appId}) => {
  
  const { id } =  useParams();

  return (
    <section className="pad-reggis session_page">
      <div className="container">
        <div className="row session_wrap">
          <div className="col-lg-12 session_left">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="Submitnotes" role="tabpanel" aria-labelledby="Submitnotes-tab">
                
                {id && id!=='' &&
                    <NotesTemplatePage history={history} appId={id} status='detailPage' />
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default withLocalize(withRouter(NotesDetailPage));
