import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {appConstants} from '../../../_Constants/app.constants';
import {getUserProfileAction} from '../../Actions/User.action';
import $ from 'jquery';

const InvoicePage = ({history}) => {
  history.listen(event => { 
    //--Reload for redirected url
    window.location = event.pathname;
    return false;
  });

  var bookData = false;
  if(history && history.location && history.location.state && history.location.state.bookingDetail){
    bookData = history.location.state.bookingDetail;
  }else if(history && history.location && history.location.state===undefined){
    history.push('/provider-list');
  }

  const [bookDetail,setBookDetail] = useState(bookData);
  const [userAddress,setUserAddress] = useState(false);

  useEffect(()=>{
    getUserProfileDetil();
    document.documentElement.scrollTop = 0;
  },[]);
  
  const getUserProfileDetil = async()=>{
    let res = await getUserProfileAction();
    if(res.success && res.data && res.data!==''){
      let address=res.data.address?res.data.address:'';
      address+=res.data.city?', '+res.data.city:'';
      address+=res.data.state?', '+res.data.state:'';
      address+=res.data.zipCode?', '+res.data.zipCode:'';
      setUserAddress(address);
    }
  }

  const printInvoiceFunction = async()=>{
    if (document.readyState === "complete") {
      $('.invoice_btns').hide();
      var Contents_Section = document.getElementById('print_setion').innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = Contents_Section;
      window.print();
      document.body.innerHTML = originalContents;
    }
    $('.invoice_btns').show();
    window.location.reload()
  }

  return (
    <section className="invoice_page pad-reggis inner_pagewrap" id="print_setion">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-left">
            <div className="faq_wrap white_box">
              <div className="section_title page_ttl text-center">
                <h3>BOOKING SUCCESSFULLY COMPLETED!</h3>
                <div className="decor2"></div>
              </div>
              <div className="invoice_wrapr row">
                <div className="col-md-6">
                  <p><strong>Patient Name: </strong>
                    {localStorage.getItem("fullName")?localStorage.getItem("fullName"):''}
                  </p>
                </div>
                <div className="col-md-6 text-md-right">
                  <p><strong>Transaction: </strong><span className="transection_id">Pending</span></p>
                </div>
                <div className="col-md-6">
                  <p> <strong>Booking Address: </strong>{userAddress?userAddress:'-'}</p>
                </div>
                <div className="col-md-6 text-md-right">
                  <p><strong> Transaction Method: </strong>{bookDetail.cardType?bookDetail.cardType.charAt(0).toUpperCase()+bookDetail.cardType.slice(1):'-'}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title"><strong>Order summary</strong></h3>
                    </div>
                    <div className="panel-body">
                      <div className="table-responsive">
                        <table className="table table-condensed not_responsive">
                          <thead>
                            <tr>
                              <td><strong>Date</strong></td>
                              <td className="text-center"><strong>Appointment Time</strong></td>
                              <td className="text-center"><strong>Price</strong></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {bookDetail.date?(moment(bookDetail.startTime).format(appConstants.DATEFORMAT)):''}
                              </td>
                              <td className="text-center">
                                {bookDetail.startTime?moment(bookDetail.startTime).format(' hh:mm A'):''} - {bookDetail.endTime?moment(bookDetail.endTime).format(' hh:mm A'):''} 
                                {bookDetail.startTime?' ('+bookDetail.duration+' min slot)':''}</td>
                              <td className="text-center">{bookDetail.amount?'$'+bookDetail.amount:0}</td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>Total</strong> (Included all tax)
                              </td>
                              <td className="no-line text-center">
                                {bookDetail.amount?'$'+bookDetail.amount:0}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 invoice_btns">
                  <Link to="/provider-list" className="btn btn-primary mb-2 mb-sm-0">Book another appointment</Link>
                  {/*<Link to="#" className="btn btn-primary mb-2 mb-sm-0">Signout</Link>*/}
                  <button to="#" onClick={e=>printInvoiceFunction(e)} className="btn btn-primary float-right mb-2 mb-sm-0">Print</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default InvoicePage;