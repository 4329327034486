import React,{useState,useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import {getPreviousDataAction} from '../../Actions/User.action';
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Modal, ModalHeader, ModalBody, Col, Row, Button, FormGroup, Table } from 'reactstrap';
import moment from 'moment';
import NotesTemplatePage from './NotesTemplate';
import {appConstants} from '../../../_Constants/app.constants';
import { Link } from 'react-router-dom';

const PreviousNotesPage = ({history, paId}) => {
  
  const [previousNotesList, setPreviousNotesList] = useState([]);
  const [modelView, setModelView] = useState(false);
  const [modal, setModal] = useState(false);  

  useEffect(()=>{
    getPreviousDataFunction();
  },[]);

  const getPreviousDataFunction = async()=>{
    let res = await getPreviousDataAction({paId:paId});
    if(res && res.success && res.data){     
      setPreviousNotesList(res.data);
    }
  }

  const forTemplateType=(cell,row)=>{   
    if(row && row.sopNoteData && row.sopNoteData.templateOption !== ''){
       let templateOption = row.sopNoteData.templateOption;
       if(templateOption==='1'){
          return ('Initial Evaluation');
       }else if(templateOption==='2'){
        return ('Progress Note');
       }else if(templateOption==='3'){
        return ('Discharge Note');
       }else if(templateOption==='4'){
        return ('Daily Note');
       }else if(templateOption==='5'){
        return ('Other Provider');
       }else{
          return ('No show Note');
       }
   }
  }
  const onDate=(cell,row)=>{   
    return (<span>{moment(row.date).format(appConstants.DATEFORMAT)+'  ('+moment(row.startTime).format('hh:mm A')+'-'+moment(row.endTime).format('hh:mm A')+')' }</span>);
  }
  const onAction=(cell,row)=>{
    //setModal(!modal);
       // setModelView(row);
        return (<a type="button" className="notesBtn" onClick={e=>toggleModal(row)}> View Notes </a>);
    /*return (<Link to={{pathname:"notes-detail/"+row._id, state:{appId: row._id}}} target="_blank"> 
          <div> View Notes </div></Link>);*/
  }
  const toggleModal = (row = {}) => {
    
    setModal(!modal);
    setModelView(row);
  }

  return (
    <section className="">
      <BootstrapTable data={previousNotesList} pagination={false}>
        <TableHeaderColumn dataField='_id' hidden={true} isKey>Id</TableHeaderColumn>
        <TableHeaderColumn width='100' dataFormat={forTemplateType}>Provider Note Template</TableHeaderColumn>
        <TableHeaderColumn width='100' dataField='date' dataFormat={onDate}>Appointment Date</TableHeaderColumn>
        <TableHeaderColumn width='100' dataFormat={onAction}>Action</TableHeaderColumn>
      </BootstrapTable>

      <Modal isOpen={modal} className="Open-x add_edit_availability" size="lg" toggle={toggleModal}>
          <ModalHeader  toggle={toggleModal} className="header-pop-unit">Notes detail</ModalHeader>
          <ModalBody className="height-full" >
            <container-fluid>             
              <NotesTemplatePage history={history} appId={modelView._id} fType={true} status='detailPage' />                         
              <FormGroup row>                    
                <Col sm="12" className="btn_sec text-center">                                                   
                    <Button className="btn btn-primary" onClick={() => toggleModal()} >Close</Button>
                </Col>               
              </FormGroup>
            </container-fluid>
          </ModalBody>
        </Modal>
    </section>
  );
}
export default withLocalize(PreviousNotesPage);
