
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { getBlogListAction ,famousProviderList} from '../../Actions/User.action';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import moment from "moment";

const FamoseProvider = ({ history }) => {
  const [blogList, setBlogList] = useState({});

  const getBlogListFunction = async () => {
    let blogData = await getBlogListAction();
    setBlogList(blogData);
  }

    const [famousPriderList, setFamousPriderList] = useState({});


  const getFamouseProviderListFunction = async () => {
    let famousProviderData = await famousProviderList();
    console.log("famousProviderData",famousProviderData)
    setFamousPriderList(famousProviderData);
  }


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    getBlogListFunction();
    getFamouseProviderListFunction();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
    console.log("searchValue",searchValue)

    if (searchInput !== '') {
     console.log("famousPriderList.data",famousPriderList.data)
      const filteredData = famousPriderList.data.filter((item) => {
          
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
        console.log("filteredData",filteredData)
    }
    else{
        setFilteredResults(famousPriderList.data)
    }
}



  return (
    <div>


      <section className="pad-reggis">
        <BreadcrumbsComp />

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="col section_title text-center">
                <h3> Provider Spotlight</h3>
                <div className="decor"></div>
                <p className='mt-3'><b> Note:&nbsp;The providers listed on this page may not necessarily be registered with Recover.  These providers consistently demonstrated a high level of expertise and professionalism, and we highly recommend them for seeking second opinions.</b></p>
              </div>
              <div className='findSpecialist'>
              <div><label className='rajaram1'>Search Provider Spotlight</label></div>  
                <input icon='search'
                className='SerchBar'
                placeholder='Search Provider Spotlight'
                onChange={(e) => searchItems(e.target.value)}
            />
                </div>
            </div>
           

          </div>
        </div>
      </section>
      <section>

      {searchInput.length > 1 ? (
      filteredResults&& filteredResults.map((item, index)=>(<div className="container">
      <div className='provider-div'>
      <div className='row mb-3'>
        <div className='col-md-3'>
          <div className='provider-left-div'>
            <div className='imgprofile'>
              {/* <img src="/static/img/providerimg.png" alt="" /> */}
              <img
                          src={`${process.env.REACT_APP_STATIC_S3_URL + item.providerImage}`}
                          alt="No Profile Pic"
                          onError={(e) => {
                            e.target.src = "/static/img/providerimg.png"
                            e.target.style = ''
                          }}
                        />
            </div>
            <div className='imgprofiledata'>
              <h3 className='text-white'>{item.fullName}</h3>
              {/* <p className='text-white'>{item.title} </p> */}
              {/* <p className='text-white'>{item.clinicName}</p>	 */}
              {item.clinicName?.length > 0 ?
                  <>
                  <p className='text-white'>{item.clinicName}</p>	
                  </>: <></>}

            </div>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='provider-right-div'>
            <div className='mb-3'>
              <h4>{item.fullName}</h4>
              <p className='mb-2'>{item.title}</p>
              <p>{item.education}</p>
              {/* <p>MS(Medicine), MBBS, FCPS</p> */}
            </div>
            <div className='row mb-3'>
              <div className='col-md-7'>
                <table >
                  <tr>
                    <th>Specialization</th>
                    <td>{item.specialty}</td>
                  </tr>
                  {item.department?.length > 0 ?
              <>
                    <th>Subspecialization</th>
                    <td>{item.department}</td>
                    </>: <></>}

                  <tr>
                

              {item.experience?.length > 0 ?
              <>
                    <th>Exprience</th>
                    <td>{item.experience}</td>
                    </>: <></>}
                  </tr>
                  <tr>
                    <th>Type of</th>
                    <td>{item.typeOf}</td>
                  </tr>
                  {/* <tr>
                    <th>Language</th>
                    <td>{item.language}</td>
                  </tr> */}
                  {item.language?.length > 0 ?
              <>
                    <th>Language</th>
                    <td>{item.language}</td>
                    </>: <></>}
                  <tr>
                    <th>Primary Location</th>
                    <td>{item.primaryLocation}</td>
                  </tr>
                </table>
              </div>
              <div className='col-md-5 call-box' >
                <h3 className='text-center'>Call {item.contact}</h3>
                <div className="text-center">
                  <Link className="btn btn-primary "  onClick={() => openInNewTab(item.webUrl)} >Visit Website</Link>
                </div>

              </div>
            </div>

            {/* <div className='specicility-box'>
              <h3>specialization</h3>
              <ul>
                <h6>{item.specialty}</h6>
               
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='raiting-box'>
        <p><span><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i>&nbsp;</span>4.9 out of 5</p>
        <p>156 Patients Satisfaction Ratings</p>
        <p>43 Patients Comments</p>
      </div> */}
      </div>
    </div> ))
      ) : (famousPriderList && famousPriderList.data && famousPriderList.data.map((item, index)=>(
        <div className="container">
          <div className='provider-div'>
          <div className='row mb-3'>
            <div className='col-md-3'>
              <div className='provider-left-div'>
                <div className='imgprofile'>
                  {/* <img src="/static/img/providerimg.png" alt="" /> */}
                  <img
                              src={`${process.env.REACT_APP_STATIC_S3_URL + item.providerImage}`}
                              alt="No Profile Pic"
                              onError={(e) => {
                                e.target.src = "/static/img/providerimg.png"
                                e.target.style = ''
                              }}
                            />
                </div>
                <div className='imgprofiledata'>
                  <h3 className='text-white'>{item.fullName}</h3>
                  {/* <p className='text-white'>{item.title} </p> */}
                  {item.language?.length > 0 ?
                  <>
                  <p className='text-white'>{item.clinicName}</p>	
                  </>: <></>}
                  
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div className='provider-right-div'>
                <div className='mb-3'>
                  <h4>{item.fullName}</h4>
                  <p className='mb-2'>{item.title}</p>
                  <p>{item.education}</p>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-7'>
                    <table >
                    <tr>
                    <th>Specialization</th>
                    <td>{item.specialty}</td>
                  </tr>
                      <tr>
                        
                        {item.department?.length > 0 ?
              <>
                    <th>Subspecialization</th>
                    <td>{item.department}</td>
                    </>: <></>}
                      </tr>
                       
                      {/* <tr>
                        <th>Exprience</th>
                        <td>{item.experience}</td>
                      </tr> */}
                      <tr>
                       {item.experience?.length > 0 ?
              <>
                    <th>Exprience</th>
                    <td>{item.experience}</td>
                    </>: <></>}
                    </tr>
                      <tr>
                        <th>Type of</th>
                        <td>{item.typeOf}</td>
                      </tr>
                      {/* <tr>
                        <th>Language</th>
                        <td>{item.language}</td>
                      </tr> */}
                       {item.language?.length > 0 ?
              <>
                    <th>Language</th>
                    <td>{item.language}</td>
                    </>: <></>}
                      <tr>
                        <th>Primary Location</th>
                        <td>{item.primaryLocation}<br></br>{item.city_and_zip}</td>
                        {/* <td></td> */}
                      </tr>
                    </table>
                  </div>
                  <div className='col-md-5 call-box' >
                    <h3 className='text-center cusor'>Call {item.contact}</h3>
                    <div className="text-center">
                      <Link className="btn btn-primary "  onClick={() => openInNewTab(item.webUrl)} >Visit Website</Link>
                    </div>

                  </div>
                </div>

                {/* <div className='specicility-box'>
                  <h3>Speciality</h3>
                  <ul>
                    <h6>{item.specialty}</h6>
                  
                    
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
         
          </div>
        </div>
         ))
        )
}

      </section>
    </div>
  );
}
export default withRouter(FamoseProvider);


