import React, { useEffect } from "react";
import { withLocalize } from "react-localize-redux";
import MetaTags from "react-meta-tags";
import BreadcrumbsComp from "../../../_Common/Breadcrumbs";

const HowRecoverWorksPage = ({ history }) => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div>
      <MetaTags>
        <title>How Recover Works</title>
        <meta
          name="description"
          content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info"
        />
        <meta
          name="keywords"
          content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore "
        />
      </MetaTags>
      <section className="how_work_page pad-reggis inner_pagewrap">
        <BreadcrumbsComp
          bcChanges={[
            { path: "/how-recover-works", breadcrumb: "How Recover Works" },
          ]}
        />

        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-left">
              <div className="how_work_wrap white_box">
                <div className="section_title page_ttl text-center">
                  <h3>How Recover Works</h3>
                  <div className="decor2"></div>
                </div>
                <h4>How Recover Works, Clients:</h4>
                <ol>
                  <li>
                  Recover simplifies the process of receiving virtual healthcare from the location of your choice. Our intuitive calendar tool lets you set your availability at your convenience, allowing us to match you with a specialist during your selected time slots for your <b>telehealth doctor appointments</b>. You can easily upload any relevant medical documents to enhance the provider's assessment and treatment. After each visit, you'll have seamless access to your treatment notes.
                  </li>
                  <li>
                    Pricing: The price per session will vary depending on the
                    provider’s rates for 60 minute or 30 minute sessions. You
                    will be able to view the price per provider when you select
                    on the provider profile.{" "}
                  </li>
                </ol>
                <h4>How Recover Works, Independent Providers:</h4>
                <ol>
                  <li>
                  Recover is actively recruiting licensed and qualified specialists to deliver <b>healthcare recovery services</b> to clients through our telehealth platform. If you're an independent contractor with a valid California healthcare license or certification and liability insurance, Recover will compensate you per client visit. You can use our calendar interface to mark your availability, and we'll connect you with clients that match your schedule. With Recover, you can build your own practice.
                  </li>
                  <li>
                    Requirements: California license/certification, W9,
                    Liability Insurance, computer with a camera/sound, as well
                    as a professional area in your house to provide telehealth
                    treatments. Providers who register as a life coach will
                    provider a copy of their certification and certification
                    date instead of a license and license number.
                  </li>
                  <li>
                    Pricing: Recover allows providers to set their own rates.
                    During the registration process, providers will view the
                    rate Recover subtracts from their total amount.
                  </li>
                </ol>
                <h4>How Recover Works, Clinics:</h4>
                <ol>
                  <li>
                  Recover provides an efficient solution for clinics dealing with last-minute client cancellations. Clinics can mark available slots using our calendar interface, and we'll pair your providers with clients available during those times. Recover's mission is to minimize revenue loss due to last-minute cancellations for clinics.
                  </li>
                  <li>
                    Each clinic registered with Recover will be given a ‘clinic
                    code’ to input when using the platform. Clinic
                    administrators have access to their providers schedules and
                    can mark the providers availability.
                  </li>
                  <li>
                    Requirements: Articles of Incorporation, W9, Liability
                    Insurance, and a computer with camera/sound for providers to
                    treat clients in a professional area.
                  </li>
                  <li>
                    Pricing: Clinic administrators will determine how much they
                    will pay their providers per 60 minute and 30 minute
                    session. Recover allows clinics to set their own rates.
                    During the registration process, clinic administrators will
                    view the rate Recover subtracts from their total amount.
                  </li>
                </ol>
                <h4>How Recover Works, Clinic Providers:</h4>
                <ol>
                  <li>
                  Recover assists in filling your cancellations during work hours and offers the opportunity to treat clients through telehealth. Your clinic administrator will provide a unique 'clinic code' for accessing your Recover account. You or your administrator can mark your availability, and we'll pair you with clients available during those times. If your clinic approves independent client treatment outside regular hours or on weekends, you have the option to do so through Recover. Clinic-based providers independently need to provide a copy of their California license/certification, W9, and proof of liability insurance.
                  </li>
                  <li>
                    If your clinic approves you to treat clients independently
                    after clinic hours (or on the weekends), you have the option
                    of doing so with Recover. Providers that treat clients at a
                    clinic are independently required to provide a copy of their
                    California license/certification, W9, and proof of liability
                    insurance.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default withLocalize(HowRecoverWorksPage);
