import React,{useState,useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {resetPasswordAction} from '../../Actions/User.action';
import {FormError, ShowFormErrors} from "../../../_Common/formError";
import toastr from "../../../_Common/toaster";
import MetaTags from 'react-meta-tags';
import './Forgot.css';
import $ from 'jquery';

const ResetPassword = ({history, setIsSignInForm, setIslogedIn}) => {
	const [signInForm,setSignInForm] = useState({});
	const [signInErrors,setSignInErrors] = useState({});
	const {handleSubmit, register, errors} = useForm();
  	const [spinner,setSpinner] = useState(false);

	const inputHandler = (e,field)=>{
		let submitFormData = signInForm;
		submitFormData[field] = e.target.value;
		setSignInForm({...signInForm,...submitFormData});
	}

	const signInFormSubmit = async () =>{
		setSpinner(true);
		let res = await resetPasswordAction(signInForm);
		if(res.success && res.code===200){
			setSpinner(false);
			toastr.show({message: res.msg, intent: "success"});
			history.push('/');
		}else{
			setSpinner(false);
			if(res.err && res.err.length > 0){
				setSignInErrors(FormError(res.err));
			}else if(res.msg){
				toastr.show({message: res.msg, intent: "danger"});
			}
		}
	}

	const resetHandler = ()=>{
		var pathname = window.location.pathname,
		pathname = pathname.substr(pathname.lastIndexOf('/') + 1);
		let submitFormData = signInForm;
		submitFormData['password'] = '';
		submitFormData['cPassword'] = '';
		if(pathname && pathname!=='reset-password'){
			submitFormData['code'] = pathname;
			$('input[name="code"]').prop('disabled', true);
		}else{
			submitFormData['code'] = '';
		}
    	setSignInForm({...signInForm,...submitFormData});
	}

	useEffect(()=>{
		  document.documentElement.scrollTop = 0;
		  resetHandler();
		
	},[]);

	return (
		<div className="slide-er">
		  	<MetaTags className="bg-rr">
				<title>Reset Password</title>
				<meta name="description" content="Recover" />
				<meta name="keywords" content="Recover" />
		  	</MetaTags>
			<section className="pad-reggis">
		      	<div className="container">
			        <div className="row">
			          <div className="form_modals reset-pp">
			            <form onSubmit={handleSubmit(signInFormSubmit)}>
				            <div className="col-md-12">		   
							    <div className="col section_title text-center">
				                    <h3>Reset Password</h3>
				                    <div className="decor"></div>
					            </div>
					            <div className="form-group">
				                   <input autoComplete="off" type="text" name="code" className="form-control"
				                       	onChange={e=>inputHandler(e,'code')} placeholder="OTP"
				                       	defaultValue={signInForm.code?signInForm.code:""} maxLength="4"
				                      	ref={register({
				                        	required: 'Required'
				                      	})}
				                    />
									<div className="text-danger">{ShowFormErrors(errors,signInErrors,'code')}</div>
								</div>
				            </div>
			                <div className="col-md-12">
			                	<div className="form-group">
				                    <input autoComplete="off" type="password"  name="password" className="form-control"
				                      	onChange={e=>inputHandler(e,'password')} placeholder="Password"
			                            ref={register({
			                              	required: 'Required',
					                        pattern: {
                          						value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,15}$/,
	                            				message: "Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
					                        }
			                            })}
				                    />
				                    <div className="text-danger">{ShowFormErrors(errors,signInErrors,'password')}</div>
				                </div>
			                </div>                
							<div className="col-md-12">
								<div className="form-group">
				                    <input autoComplete="off" type="password" name="cPassword" className="form-control"
				                      	onChange={e=>inputHandler(e,'cPassword')} placeholder="Confirm Password"
									  	ref={register({ 
									  		required: 'Required',
									  		validate: (value) => value === signInForm.password || "Passwords don't match.",
									  	})}
				                    />
									<div className="text-danger">{ShowFormErrors(errors,signInErrors,'cPassword')}</div>
								</div>
							</div>                
			                <div className="col-md-12 text-center">
			                    <input type="reset" onClick={e=>resetHandler()} className="btn btn-primary rt" value="Clear" />
			                    <input type="submit" className="btn btn-primary" value="Reset Password" />
			                </div>
			           	</form>
			          </div>
			        </div>
		      	</div>
			</section>
			<div className="spinner" style={{display:spinner?'block':'none'}}>
		        <img src="/static/img/spinner.gif" alt='loader'/>
		    </div>
		</div>
    );
}
export default withRouter(ResetPassword);
