import React,{ useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideMenu from '../DashboardPage/SideMenu';
import PatientProfileComponent from '../DashboardPage/Profile/PatientProfilePage';
import PtProfileComponent from '../DashboardPage/Profile/PtProfilePage';
import ClinicProfileComponent from '../DashboardPage/Profile/ClinicProfilePage';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const EditClinicPtPage = (props) => {
	var cptId = '';
	if(props && props.location && props.location.state==='' || props.location.state===undefined){
		props.history.push('clinic-pt');
	}else{
		cptId = props.location.state.cptId;
	}

  const dispatch = useDispatch();
	return (
		<div>
			<MetaTags className="bg-rr">
				<title>Edit A Provider Account</title>
				<meta name="description" content="Recover" />
				<meta name="keywords" content="Recover" />
			</MetaTags>

		    <section className="dash_section pad-reggis">
		    	<BreadcrumbsComp bcNewItems={[
					{ path: '/', breadcrumb: "Home" },
					{ path: '/clinic-pt', breadcrumb: 'List Of Providers'},
					{ path: '/edit-clinic-pt', breadcrumb: 'Provider Profile'},
				]} />

			    <div className="container">
					<PtProfileComponent history={props.history} cptId={cptId} />
			    </div>
		    </section>
		</div>
	);
}
export default withRouter(EditClinicPtPage);
