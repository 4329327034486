import React,{useState,useEffect} from 'react';
import { withRouter,Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import SideMenu from '../DashboardPage/SideMenu';
import moment from 'moment';
import {getAvailabilityList, getCalanderAvailability, getPTAvailabilityList,specializationList} from '../../Actions/User.action';
import Calendar from 'react-calendar';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import {toUTC, getLocalDate} from '../../../_Constants/app.constants';
import Multiselect from 'multiselect-react-dropdown';

const AvialabilityCalendar = (props) => {
  const [availabilityList, setAvailabilityList] = useState();
  const [toValue,setToValue] = useState('');
  const [fromValue,setFromValue] = useState('');
  const [spinner,setSpinner] = useState(false);
  const pageTitle = localStorage.getItem("userType")==='PATIENT'?'Check Provider/Clinic Availability':'See Availability';
  const pageSubTitle = localStorage.getItem("userType")==='PATIENT'?'Please choose a date(s), and click "Search for Provider/Clinic" button to proceed with scheduling.':'';

  const onChangeHandle = (date)=>{
      
      if(date[0] && date[1]){
          setToValue(date[1]);
          setFromValue(date[0]);
      }else if(date[0]){
          setFromValue(date[0]);
      }
  }

const onClickHandle = (date)=>{
  
  setFromValue(date);
  setToValue('');
}
const [signupForm,setSignupForm] = useState({specialization:[]});

  const inputSelectHandler = (value,field)=>{
		
		// console.log("lllllllllll",value,field)
		let signupFormData = signupForm;
		signupFormData[field] = value;
    	setSignupForm({...signupForm,...signupFormData});
	}

  const addEventClass = (date, view)=>{
    
    var classT = '';
    
    date = getLocalDate(date);
    
    for(let i in availabilityList){
     
        if(moment(availabilityList[i].date).format("YYYY-MM-DD") == date){
        
            classT =  'available';
        }
    }
    return classT;
  }

  const tileDisabled = (date)=>{
    var classT = true;
    
    if(localStorage.getItem("userType")!=='PT'){
      
        date = getLocalDate(date);
        for(let i in availabilityList){
          if(moment(availabilityList[i].date).format("YYYY-MM-DD") == date){
              classT =  false;
          }
        }  
    }else{
      classT =  false;
    }
        
    return classT;
  }

  const getAvailabilityListData = async()=>{
    setSpinner(true);
       let dataList = []; 
      if(localStorage.getItem("userType")==='PT'){ 
          let date = new Date();
          let to = new Date(date.getFullYear(), date.getMonth() + 2, 0);
          to = moment(to).format('YYYY-MM-DD');

          let from = moment().clone().startOf('month').format('YYYY-MM-DD');        
          //let to   = moment().clone().endOf('month').format('YYYY-MM-DD');
        
          var list = await getAvailabilityList({to:toUTC(to),from:toUTC(from)});
          dataList =  list.data.list; 
      }else{

          var list = await getPTAvailabilityList({startDate:toUTC(moment().add(-1, 'days').format("YYYY-MM-DD")),isAvailable:true});
          let calanderData =  list.data; 
          console.log("calanderData",list.data)
          let lastDate = '';
         
          for(let i in calanderData){
            
              let dateArr = calanderData[i].dateArr;
              
              for(let k in dateArr){
                let timeArr = dateArr[k].time;
                for(let l in timeArr){
                    let startDate = moment(timeArr[l].startTime).format('YYYY-MM-DD');
                    let endDate = moment(timeArr[l].endTime).format('YYYY-MM-DD');
                    if(moment().format('YYYY-MM-DD') <= startDate && startDate != lastDate){
                        lastDate = startDate;
                        dataList.push({date:startDate});                      
                    } 
                  
                    if(moment().format('YYYY-MM-DD') <= endDate && lastDate != endDate){
                      dataList.push({date:endDate});
                      lastDate = endDate;
                    }
                }
              }
          }
      }
      setAvailabilityList(dataList); 
          
      setSpinner(false); 
  }


  const [imageData, setImageData] = useState([]);

  useEffect(()=>{
      const specializationListtt = async()=>{
      const getspecializationName=[];
      console.log("getspecializationName",getspecializationName)
        let dataList = await specializationList(); 
      console.log("dataList",dataList.data)
        for(let i= 0; i < dataList.data.length; i++)
      {
      getspecializationName.push({"specializationName":dataList.data[i].name, "specializationId": dataList.data[i]._id})
      }
      setImageData(getspecializationName);
    }
    specializationListtt();
  },[]);
  
  

   useEffect(()=>{
      getAvailabilityListData();
      

    },[]);
    return (
      <div>
        <MetaTags className="bg-rr">
          <title>{pageTitle}</title>
          <meta name="description" content="Recover" />
          <meta name="keywords" content="Recover" />
        </MetaTags> 
        <section className="dash_section pad-reggis">
          <BreadcrumbsComp bcChanges={[{path: '/calendar', breadcrumb:pageTitle  }]} />
          
          <div className="container">
            <div className="row">
              <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
                <SideMenu/>
              </div>
              <div className="col-md-9 calender_page right_dashboard scrollbar3 rightContainer">  
              <div className="content-setion">  
                <div className="section_title m-25">
                  <h3>
                    {pageTitle}
                    <div className="Colordetector">
                      <div><span className="sbluedetector"></span> Selected Day(s) for Appointment</div>
                      <div><span className="bluedetector"></span> Provider Available </div>
                      <div><span className="greydetector"></span> No Slot Available </div>
                    </div>
                  </h3>
                </div>       
                {pageSubTitle != '' &&
                  <h5>{pageSubTitle}</h5> 
                }                           
                <Calendar
                  calendarType={"US"}         
                  selectRange={true}                 
                  onChange={onChangeHandle}
                  onClickDay={onClickHandle}
                  //value={new Date()}
                  tileClassName={({ date, view }) => addEventClass(date, view)} 
                  tileDisabled={({ date}) => tileDisabled(date)}   
                  minDate={localStorage.getItem("userType")!=='PT'?new Date():''}                        
                />
                  {/* <div className="col-lg-6">
                    <label>Specialization* </label>

                    <div className="form-group">
                      <Multiselect
                        name="specialization"
                        // value={signupForm.specialization ? signupForm.specialization : ''}
                        onSelect={e => inputSelectHandler(e, 'specialization')}

                        isObject={true}
                        options={imageData}
                        showCheckbox
                        //  onSelect={(event)=>{console.log(event,"gggggggggg")}}
                        onRemove={(event) => { console.log(event) }}
                        displayValue="specializationName"
                      />

                    </div>
                  </div> */}

                {localStorage.getItem("userType")==='PT' &&
                  <Link to={{ pathname: '/my-availability', state:{ to:toValue, from:fromValue}}} className="btn-fill btn-wd btn btn-info">Select date (s) to mark available time</Link>
                }

                {localStorage.getItem("userType")!=='PT' &&
                  <Link to={{ pathname: '/provider-list', state:{to:toValue, from:fromValue ,signupForm:signupForm}}} className="btn btn-primary">Search For Provider/Clinic</Link>
                }
              </div>  
              </div>  
            </div>                             
          </div> 
        </section> 
        <div className="spinner" style={{display:spinner?'block':'none'}}>
          <img src="/static/img/spinner.gif" alt='loader'/>
        </div> 
    </div>           
  );
}
export default withLocalize(withRouter(AvialabilityCalendar));
