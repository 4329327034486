import React,{useState} from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import {checkExtFunction} from '../_Constants/app.constants';
import {deletePaDocsAction} from '../FrontEnd/Actions/User.action';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import toastr from "../_Common/toaster";
import renderHTML from 'react-render-html';
import $ from 'jquery';

const bodyPartList = [
  {id: 'Muscle weakness', title: 'Muscle weakness'},
  {id: 'Gait & Mobility', title: 'Gait & Mobility'},
  {id: 'Balance', title: 'Balance'},
  {id: 'Neck', title: 'Neck'},
  {id: 'Back', title: 'Back'},
  {id: 'Shoulder', title: 'Shoulder'},
  {id: 'Elbow', title: 'Elbow'},
  {id: 'Wrist', title: 'Wrist'},
  {id: 'Hand', title: 'Hand'},
  {id: 'Arm', title: 'Arm'},
  {id: 'Hip', title: 'Hip'},
  {id: 'Pelvic', title: 'Pelvic'},
  {id: 'Gluteal', title: 'Gluteal'},
  {id: 'Leg', title: 'Leg'},
  {id: 'Knee', title: 'Knee'},
  {id: 'Ankle', title: 'Ankle'},
  {id: 'Foot', title: 'Foot'},
  {id: 'Vertigo', title: 'Vertigo'},
]

const AddMoreFileUpload = ({history, parentCallback, deleteOption ,formData,apiCall}) => {
  const [name,setName] = useState({});
  const [shareholders,setFileHolders] = useState((formData && formData.length)?formData:[{ title: "",imgPath:'',size:'',imgPathPreview:''}]);
  const [errorMessage,setErrorMessage] = useState();

  const [totalFileSize,setTotalFileSize] = useState();
  
  const handleShareholderNameChange = (idx,name) => evt => {
    const newShareholders = shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, [name] : ((evt)? ((evt.label)?evt.value : ((evt.target.files)? evt.target.files[0] :evt.target.value)):"") };
    });
    setFileHolders(newShareholders);
    parentCallback(newShareholders);
  };  

  const handleShareholderFileChange = (idx,name) => evt => {
    let checkFileSize = 0;
    const newShareholders = shareholders.map((shareholder, sidx) => {
      if(shareholder.size && shareholder.size!==''){
        checkFileSize += shareholder.size;
        if(idx===sidx){
          checkFileSize = checkFileSize-shareholder.size;
          checkFileSize = checkFileSize+evt.target.files[0].size;
        } 
      }else{
        if(idx===sidx)
        checkFileSize += (evt && evt.target && evt.target.files && evt.target.files[0].size ? evt.target.files[0].size:'');
      }
      
      setTotalFileSize(checkFileSize);
      if(evt && evt.target && evt.target.files && evt.target.files[0] && idx === sidx){
        setErrorMessage(false);
        let fileName = evt.target.files[0].name;
        let fileSize = evt.target.files[0].size;
        let filePreview = '';
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
        if (extensions === 'pdf'){
          filePreview = '/static/img/pdfIcon.png';
        }else if(extensions === 'docx' || extensions === 'doc' || extensions === 'csv'){
          filePreview = '/static/img/docIcon.png';
        }else if(extensions === 'bmp' || extensions === 'jpg' || extensions === 'png' || extensions === 'jpeg'){
          filePreview = URL.createObjectURL(evt.target.files[0]);
        }else{
          $("#imgPath"+idx).val('');
          setErrorMessage('Docs should be in PDF, Word, Excel, PNG, JPG and JPEG Format');
          return { ...shareholder, ['size'] : fileSize, [name+'Preview'] : filePreview};
        }

        let sizeInMB = checkFileSize/1024/1024;
        if(sizeInMB.toFixed(2) > 5){ 
          $("#imgPath"+idx).val('');
          setErrorMessage('Maximum of 5 MB docs can be uploaded');
          return { ...shareholder, ['size'] : fileSize, [name+'Preview'] : filePreview};
        }

        return { ...shareholder, [name] : evt.target.files[0], ['size'] : fileSize, 
        [name+'Preview'] : filePreview};        
      }else{
        return shareholder;
      }
    });
    setFileHolders(newShareholders);
    parentCallback(newShareholders);
  };

  const handleSubmit = evt => {
    const { name, shareholders } = this.state;
    alert(`Incorporated: ${name} with ${shareholders.length} shareholders`);
  };

  const handleAddShareholder = idx => () => {
    if(shareholders && shareholders.length < 5){
      const shareholdersNew = shareholders.concat([{ title: "",imgPath:'',size:'',imgPathPreview:''}])
      setFileHolders(shareholdersNew);
      parentCallback(shareholdersNew);
    }
  };

  const handleRemoveShareholder = idx => () => {
    let newSize = totalFileSize-shareholders[idx].size;
    setTotalFileSize(newSize);
    newSize = newSize/1024/1024;
    if(newSize.toFixed(2) < 5){
      setErrorMessage(false);
    }

    setTotalFileSize(totalFileSize-shareholders[idx].size);
    setFileHolders(shareholders.filter((s, sidx) => idx !== sidx));
    shareholders.splice(idx, 1);
    parentCallback(shareholders);
  };

  const onDeleteDocs = async (e,id,index) =>{
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure to do this?',
      buttons: [{
          label: 'Yes',
          onClick: async() => {               
            let reponse = await deletePaDocsAction({_id:id});                
            if(reponse.success){    
              //shareholders.splice(index,1);
              $("#remove"+index).remove();
              toastr.show({message: "Deleted successfully",intent: "success"}); 
            }
          }
        },
        {label: 'No',}
      ]
    });
  }
  //console.log(shareholders,'DDDDDDDDDDDDDDDDDDDDDDDDDDD',  [optionType[optionType.findIndex(x => x.value===101)]]);

  return (
    <div>
      <div className="shareholder">
        {shareholders && shareholders.map((shareholder, idx) => (
          <div className="row" key={idx} id={'remove'+idx}>
            {/*<div className="col-lg-4">
              <input type="text" name='name' placeholder={`Title #${idx + 1}`}
                value={shareholder.title} onChange={handleShareholderNameChange(idx,'title')}
                className="form-control" required disabled={shareholder._id?true:false} />
            </div>*/}
            <div className="col-lg-4">
              <select className="form-control" onChange={handleShareholderNameChange(idx,'title')} disabled={shareholder._id?true:false}>
                <option value=""> Select a diagnosis </option>
                {bodyPartList && bodyPartList.map((val, int)=>{
                  return(<option value={val.title} selected={val.title===shareholder.title?true:false} key={int}>{val.title}</option>)
                })}
              </select>
              {/*shareholder.title==='' &&
                <div className="text-danger" id='titleMsg'>Required</div>
              */}
            </div>
            <div className="col-lg-4">
              <input type="file" name="imgPath" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc,.csv" 
                onChange={handleShareholderFileChange(idx, "imgPath")} 
                //required={errorMessage || shareholder.imgPath===''?true:false}
                disabled={shareholder._id?true:false}
                id={'imgPath'+idx}
              />
              {shareholder.imgPathPreview && shareholder.imgPathPreview !=='' ?
                (<div className="fileDocs"><img src={shareholder.imgPathPreview} alt="img"/></div>):
                (<div className="fileDocs">{shareholder.imgPath?renderHTML(checkExtFunction(shareholder.imgPath)):''}</div>)
              }
              {/*shareholder.imgPath==='' &&
                <div className="text-danger" id='imgPathMsg'>Required</div>
              */}
            </div>
            <div className="col-lg-2">
              {deleteOption && !shareholder._id && (shareholders && shareholders.length > 1) &&
                <input
                  type="button"
                  onClick={handleRemoveShareholder(idx)}
                  className="small" value="-"/>
              }
              {shareholders && shareholders.length < 5 &&
                <input
                  type="button"
                  onClick={handleAddShareholder(idx)}
                  className="small" value="+"/>
                }
            </div>            
            <div className="col-lg-2">
              {deleteOption && shareholder._id &&
                <button type="button" className="small_red"
                  onClick={e=>onDeleteDocs(e,shareholder._id,idx)}>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              }
            </div>
          </div>
        ))}
        <div className="col-lg-12 error" role="alert">
          {errorMessage}
        </div>
      </div>
    </div>
  );
}
export default withLocalize(withRouter(AddMoreFileUpload));
