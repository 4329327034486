import axios from "axios"
import toastr from "../_Common/toaster";
var CancelToken = axios.CancelToken;
let wj_cancel ;
let resp;
export const request = (path, data, method) => {

	
	if(wj_cancel && resp && resp.config && resp.config.url == `${process.env.REACT_APP_API_URL}/${path}`){
			wj_cancel && wj_cancel(); 
			
	}
	var options = { 
		method: method,
		url: `${process.env.REACT_APP_API_URL}/${path}`,
		headers: {
			"Content-Type":"application/json",
			"Authorization":"key@123"
		},
		dataType: 'json',
		cancelToken: new CancelToken(function executor(c) {            
            wj_cancel = c;
        })
	};
	
	if((localStorage.getItem("RecoverWebStatus") || localStorage.getItem("RecoverAdminStatus")) && path != 'forgotPassword' && path != 'resetPassword'){
		options.headers["Authorization"] = localStorage.getItem("jwtToken");
	}else{
		options.headers["Authorization"] = "key@123";
	}
	if(method==="GET"){
		options["params"] = data;
	}
	else{
		options["data"] = data;
	}
	
	let responce = axios(options);
	responce.then(res1=>{
		resp = res1;
		if(res1.data.code===402){
			localStorage.clear();
		    toastr.show({message: res1.data.msg,intent: "danger"});
		    window.location = "/";
		    //history.push('/');
		}
	})
	return responce;
}

export const postRequest = (path, data) => request(path, data, "POST")
export const putRequest = (path, data) => request(path, data, "PUT")
export const getRequest = (path, data) => request(path, data, "GET")