import React,{useState,useEffect} from 'react';
import { withRouter, Link, useParams  } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {appConstants} from '../../../_Constants/app.constants';
import {getBlogListAction,getBlogDetailAction} from '../../Actions/User.action';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import moment from "moment";

const BlogDetailPage = ({history}) => {
  const [blogDetail,setBlogDetail] = useState({});
  const [blogList,setBlogList] = useState({});
  
  const params = useParams();

  const getBlogListFunction = async()=>{
    let blogData = await getBlogListAction();   
    setBlogList(blogData);
  }

  const getBlogDetailFunction = async()=>{
      if (params.title) {
        let blogData = await getBlogDetailAction({slug:params.title});    
        setBlogDetail(blogData.data);  
      }
      else if(history && history.location && history.location.state && history.location.state.id!==''){
        console.warn("camee",history.location.state.id )
        let blogData = await getBlogDetailAction({_id:history.location.state.id});    
        setBlogDetail(blogData.data);   
      }
      else{
        history.push('blog');
      }
  }  

  const changeDetailFunction = async (e, id) =>{
    if(id && id!==''){
      let blogData = await getBlogDetailAction({_id:id});    
      setBlogDetail(blogData.data);   
    }else{
      history.push('blog');
    }
  }

  useEffect(()=>{
    document.documentElement.scrollTop = 0;
    getBlogListFunction();
    getBlogDetailFunction();
  },[]);

  return (
    <div>
      <MetaTags>
        <title>Blog Detail</title>
        <meta name="description" content="We are working professionally - to put diversified professional’s extra mile ahead; to make e-learning more efficient, easy and accessible to everyone, anywhere and anytime" />
        <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore" />
      </MetaTags>
      <section className="pad-reggis">
        <BreadcrumbsComp bcNewItems={[
          { path: '/', breadcrumb: "Home" },
          { path: '/blog', breadcrumb: 'Blog'},
          { path: '/blog-detail', breadcrumb: 'Blog Detail'},
        ]} />

        <div className="container">
          <div className="row">
            <div  className="col-md-12">
              <div className="col section_title text-center">
                <h3>Blog Detail</h3>
                <div className="decor"></div>
              </div>
            </div>
            {/*<div className="col-md-4">
              <div className="right-sect-blog">
                <div className="recent-heading">
                  <h4>Recent Post</h4>
                </div>
                <div className="recent-b-lidt">
                  <ul className="text_content">
                    {blogList && blogList.data && blogList.data.map((item, ind) => {
                      if (ind < 5) {
                        return (
                          <li key={ind}>
                            <Link to='#' onClick={e=>changeDetailFunction(e, item._id)}>
                              {item.name?item.name:'-'}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div> 
            </div> */}

            <div className="col-md-12">
             {blogDetail&& <div className="bol-portion row">
              <div className="img-wi blog-details col-md-4">
                <img
                  src={`${process.env.REACT_APP_STATIC_S3_URL+blogDetail?.blogImg}`}
                  alt="example"
                  onError={(e) => {
                    e.target.src = "/static/img/blog-img.jpg"
                    e.target.style = ''
                  }}
                />
              </div>
                <div className="inline-sect col-md-8">
                  {/*<div className="calendar-sec">
                    <div className="blog-month">
                        {blogDetail.createdAt?moment(blogDetail.createdAt, "YYYY-MM-DD").format("MMMM"):'-'}
                    </div>
                    <div className="blog-date">
                        {blogDetail.createdAt?moment(blogDetail.createdAt, "YYYY-MM-DD").format("D"):'-'}
                    </div>
                  </div>*/}
                  <div className="blog-part-text img-detailx text_content">
                    <span className="r-secc"><i className="fas fa-calendar-alt"></i>&nbsp;
                      {blogDetail?.createdAt?moment(blogDetail?.createdAt, "YYYY-MM-DD").format(appConstants.DATEFORMAT):'-'}
                    </span>
                    {/*<span><i className="fas fa-clock"></i>&nbsp;
                      {blogDetail.createdAt?moment(blogDetail.createdAt, "YYYY-MM-DD hh:mm").format(appConstants.TIMEFORMAT):'-'}
                    </span>*/}
                    <h4>{blogDetail?.name?blogDetail?.name:'-'}</h4>
                    <p>
                      {blogDetail?.desc?blogDetail?.desc.replace(/<(?:|\/div|\/p)>/g, "\n").replace(/&nbsp;|<.*?>/g, ""):'-'}
                    </p>
                  </div>
                </div>
              </div>}
            </div> 
          </div>
        </div>
      </section>
    </div>
  );
}
export default withRouter(BlogDetailPage);
