import ReactDOM from 'react-dom';
import { makeMainRoutes } from './FrontEnd/routes/routes';
import {suerAdmin} from './SuperAdmin/index';
import {appConstants, setCookie, getCookie} from './_Constants/app.constants';
const moment = require('moment-timezone');

const routes = makeMainRoutes();
let str = routes.props.children.props.history.location.pathname;

moment.tz.setDefault(appConstants.timeZone);
//console.log(window.history);

if(window.location.href.includes("https://www.recover.healthcare")) {
	let url = window.location.href
	url = url.replace("https://www.recover.healthcare","https://recover.healthcare")
	window.location.href = url
}
if(window.location.search == '?mail' && window.location.pathname!='' && window.location.pathname!='/'){
	
	var d = new Date();
	d.setTime(d.getTime() + (5*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = "lastUrl=" + window.location.pathname + ";" + expires + ";path=/";
	//return false;
}

//console.log(getCookie('lastUrl'));

//moment().utcOffset("+08:00");
if(str.indexOf('/administrator') > -1 || str.indexOf('/login') > -1 || str.indexOf('/admin') > -1){
	suerAdmin();
}else{
	ReactDOM.render(
  		routes,
  		document.getElementById('root')
	);
}

