import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import MetaTags from "react-meta-tags";
import BreadcrumbsComp from "../../../_Common/Breadcrumbs";
import { siteDetail, appConstants } from "../../../_Constants/app.constants";
import { getUserProfileAction } from "../../Actions/User.action";
import moment from "moment";

const ClinicTerm = ({ history }) => {
  const { id } = useParams();
  const [userDetail, setUserDetail] = useState({});
  const [userType, setUserType] = useState(1);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (id) {
      getUserProfileDetil(id);
    } else if (localStorage.getItem("RecoverWebStatus")) {
      getUserProfileDetil(localStorage.getItem("_id"));
    }
  }, []);

  const getUserProfileDetil = async (id) => {
    let userDetail = await getUserProfileAction({ userId: id });
    console.log("userDetail",userDetail)
    if (userDetail && userDetail.data !== "") {
      if (
        userDetail.data.userType === "CLINIC" ||
        (userDetail.data.userType === "PT" && userDetail.data.clinicId === "")
      ) {
        //--Clinic/Ipt
        setUserType(2);
      } else if (userDetail.data.userType === "PT") {
        //--cPT
        setUserType(3);
      } else {
        setUserType(1);
      }
      setUserDetail(userDetail.data);
    }
  };

  return (
    <div>
      <MetaTags>
        <title>Recover - Terms Condition | Physical Therapy Treatment</title>
        <meta
          name="description"
          content="Recover helps patients find physical therapy treatment and providers reduce revenue loss & accommodate more patients. Read all the Terms & Conditions here!"
        />
        <meta
          name="keywords"
          content="telehealth, providers near me , Find A Provider, Online Therapy San Diego, Find the Right Provider, Teletherapy , Best Providers/Counselors Near Me in San Diego, Virtual Therapy, Clinical Psychologist, Find best Psychologist near me, Best Psychologists in San Diego, best physical therapy near me, physical therapy in californai, providers near me, best psychologist near san diego, psychology today find a provider , recover healthcare san diego, top psychologist near me, psychotherapy san diego, physical therapy Clinic san diego, online physical therapy programs near san diego, physical therapy california, virtual physical therapy near san diego, advanced physical therapy, virtual pt in california, recover healthcare california, recovery health services san diego, physical therapy in california, find a provider in san diego, top rated physical therapy near san diego, physical therapy facilities near me, physical therapy around me, pt therapy near me, provider california, physical therapy san diego, physical therapy los angeles, telehealth solutions, select physical therapy los angeles, best recovery healthcare in san diego, telehealth solutions in san diego, good psychologist near me, clinical psychologist california, provider santa maria, digital telehealth solutions, physical therapy livermore ca, find a provider in california, how to find the right provider for me, san diego psychotherapy and wellness center, independent providers california, recover healthcare, recovery health services, best recovery healthcare, online provider san diego, physical therapy Clinic near me, Telehealth physical therapy California, Virtual physical therapy California, Face time physical therapy California, physical therapy treatment San Diego, provider San Diego, Telehealth physical therapy San Diego, Virtual physical therapy San Diego, Face time physical therapy San Diego, Independent Providers San Diego, Clinic Providers San Diego, provider Services San Diego"
        />
      </MetaTags>

      <section className="terms_con__page pad-reggis inner_pagewrap">
        <BreadcrumbsComp
          bcNewItems={[
            { path: "/", breadcrumb: "Home" },
            { path: "/terms-condition", breadcrumb: "Terms Conditions" },
          ]}
        />

        <div className="container">
          <div className="white_box">
            <div className="col-lg-12 col-sm-12">
              <div className="section_title page_ttl text-center">
                <h3> Terms and Conditions </h3>
                <div className="decor2"></div>
              </div>
            </div>
            <div className="cotent-statics">
              
              
            <div>
                  {/* <h1 className="text-center">PHYSICAL THERAPY CLINIC</h1> */}
                  <h2 className="text-center">
                  CLINIC TERMS AND CONDITIONS
AGREEMENT FOR SERVICES 

                  </h2>
<p>THIS INDEPENDENT CONTRACTOR AGREEMENT (“Agreement”) is entered into between Recovery Physical Therapy, Inc., a California corporation, having its principal place of business at 5694 Mission Center Rd #602-121 San Diego, CA 92108 (“Company”) and [Clinic Name] (“Clinic”) as of [Registration Date].  This Agreement establishes the terms and conditions under which the Clinic will provide services to the Company as an independent contractor. The parties agree to be bound by the terms and conditions set forth herein.</p>
                  {/* <p>
                  THIS INDEPENDENT CONTRACTOR AGREEMENT 
                    (“Agreement”) is entered into as of{" "}
                    {userDetail && userDetail.createdAt
                      ? moment(userDetail.createdAt, "YYYY-MM-DD").format(
                          appConstants.DATEFORMAT
                        )
                      : ""}{" "}
                    by and between Recovery Physical Therapy., Inc. a
                    California corporation, having its principal place of
                    business at <u>{siteDetail.address}</u> (“Company”) and{" "}
                    {userDetail && userDetail.fullName} an independent
                    contractor, having its address at{" "}
                    {userDetail && userDetail.address
                      ? userDetail.address +
                        " " +
                        userDetail.city +
                        " " +
                        userDetail.state +
                        " " +
                        userDetail.zipCode
                      : ""}{" "}
                    (“Clinic”).
                  </p> */}
                  <h2 className="text-center">SERVICES</h2>
                  <ol start="1">
                    <li>
                      Clinic agrees to perform the services described in Exhibit
                      “A” (the “Services”) attached to this Agreement.
                    </li>
                    <li>
                      Clinic will determine the method, details, and means of
                      performing the Services, including but not limited to
                      diagnostics and development of treatment plan and follow
                      up to the same.
                    </li>
                    <li>
                      Clinic may, at Clinic's own expense, use employees or
                      other subcontractors to perform the Services under this
                      Agreement, so long as Clinic advises company of the same.
                    </li>
                  </ol>

                  <h2 className="text-center">COMPENSATION AND REQUIREMENTS</h2>
                  <ol start="1">
                    <li>
                    	Company agrees to pay Clinic on a project basis: When Clinic Registers a Clinic Provider, Clinic sees percentage or dollar amount Company will collect from Clinic per 60 minute and 30 minute service.  Clinic sets its own rates for each Clinic Provider to receive per service. 
                    </li>
                    <li>
                    	Clinic shall be responsible for all expenses incurred in association with the performance of the Services, including but not limited to obtaining and maintaining contractors own general liability insurance as well as professional liability insurance; general cost of doing business overall; and securing payment of business licenses for the State of California or the respective areas where services are being performed.
                    </li>
                    <li>
                    	Furthermore, Clinic shall provide IRS Form W9 to Company; Clinic shall provide EIN to Company.  Clinic shall confirm that Clinic is a formed business entity in good standing; previously referenced insurances, Clinic shall provide a copy of certificates of insurance for all insurance Clinic maintains, including but not limited to E&O; Workers’ Comp Insurance or waiver by State Fund; and General Liability Insurance. Clinic must have functional computer with camera and sound.                    </li>
                    <li>
                    	Furthermore, upon reasonable notice Clinic shall provide evidence that
Clinic is using its own payroll services for calculation its own payment to
employees that are employed by Clinic on a timely basis.

                    </li>
                  </ol>

                  <p>
                    
                  </p>

                  <h2 className="text-center">PENALTIES</h2>
                  <p>
                  If Clinic cancel less than 24 hours in advance 3 times, then the Clinic is suspended from using Recover website and platform.  Company has the right to remove or sustain access to Clinic in its sole discretion. 
                  </p>
                  <h2 className="text-center">TERMS OF AGREEMENT</h2>
                  <ol>
                    <li start="1">
                      This Agreement will become effective on{" "}
                      {userDetail && userDetail.createdAt
                        ? moment(userDetail.createdAt, "YYYY-MM-DD").format(
                            appConstants.DATEFORMAT
                          )
                        : ""}
                      . This Agreement will automatically terminate on the completion of the Services, unless it is terminated earlier as set forth below. 
                    </li>
                    <li>
                    	Clinic must have updated license and liability insurance documents with expiration dates current in Recover website/platform.  Company’s system will automatically terminate Company’s use of the Clinic if the license or liability insurance is not updated before document expiration.  
                    </li>

                    <li>
                    	Should either party default in the performance of this Agreement or materially breach any of its provisions, the nonbreaching party may terminate this Agreement by giving written notification to the breaching party. Termination shall be effective immediately on receipt of the notice, or five (5) days from mailing of the notice, whichever occurs first. For the purposes of this section, material breach of this Agreement shall include but not be limited to the following:
                      <ul>
                        <li>
                        	 Nonpayment of compensation by Company after twenty (20) days’ written demand for payment.
                        </li>
                        <li>
                        Failure of Clinic to provide the services set forth in Exhibit “A.”
                        </li>
                      </ul>
                    </li>

                    <li>
                    	This Agreement terminates automatically on the occurrence of any of the following events: (a) bankruptcy or insolvency of either party; (b) sale of the Company or of Clinic; or (c) violation of the law by either party.
                    </li>
                  </ol>

                  <h2 className="text-center">RELATIONSHIP OF THE PARTIES</h2>
                  <ol>
                    <li start="1">
                    Clinic enters into this agreement as, and shall continue to be, an independent contractor. Under no circumstances shall Clinic look to Company as his/her employer, or as a partner, agent, or principal. Clinic shall not be entitled to any benefits accorded to Company’s employees, including workers’ compensation, disability insurance, vacation, or sick pay. Clinic shall be responsible for providing, at Clinic’s expense, and in Clinic’s name, disability, workers’ compensation, or other insurance as well as licenses and permits usual or necessary for performing the Services.  As previously referenced, certificates of insurance for all requisite insurance policies to do business shall be provided upon request to Company.
                    </li>

                    <li>
                    Clinic shall pay, when and as due, all taxes incurred as a result of Clinic’s compensation, including estimated taxes, and shall provide Company with proof of payment on demand. Clinic indemnifies and holds Company harmless from any claims, losses, costs, fees, liabilities, damages, or injuries suffered by Company arising out of Clinic’s breach of this section.
                    </li>

                    <li>
                      <strong>
                      	Furthermore, parties hereto acknowledge that they are aware of and have read and have reviewed, sought legal advice or have by not doing so have waived their opportunity to seek legal advice regarding the rules under California Labor Code Section 226.8 and relevant California law regarding classification of workers.  All parties to the Agreement, Clinic and Company realize that there are multiple criteria for determination of classification of independent contractors, and both parties understand that on the one hand the Company is an independent business and on the other hand, the clinic is truly an independent contractor is not an employee under the laws of California.  In making such a determination and agreement, both parties have reviewed the issues of misclassification as described in California Labor Code Section 226.8 and other relevant laws, and acknowledge that they understand the same and comply with the same.
                      </strong>
                    </li>

                    <li>
                    Clinic agrees to devote the time necessary to get the job done on a project by project basis as determined by Clinic.  Company will provide the actual patients who are in need of services and Clinic will then perform the services as described in Exhibit “A” including but not limited to diagnosis and analysis and development of client treatment for each particular project.  (Project and Patient are interchangeable throughout this Agreement).  Consistent with requirements of the Agreement, Clinic may represent, perform services, or be employed by any persons or companies as Clinic sees fit to render similar services or different services.
                    </li>
                  </ol>

                  <h2 className="text-center">
                    CONTRACTOR’S REPRESENTATIONS AND INDEMNITIES
                  </h2>
                  <ol>
                    <li start="1">
                    	Clinic represents and warrants to Company that Clinic employees have the qualifications and ability as well as the certification and all licensure requirements to perform those Services normally performed in the ordinary course by healthcare providers.  
                    </li>

                    <li>
                    	Furthermore, Clinic represents and warrants to Company that (i) Clinic has the ability to perform the Services in a professional manner, without the advice, control, or supervision of Company; (ii) its Services will not infringe upon, misappropriate or violate the intellectual property or privacy or personal rights of any third party or violate any applicable laws, rules or regulations; (iii) Clinic has the legal power and authority to enter into this Agreement; and (iv) Clinic’s execution, delivery, and performance of this Agreement does not and will not conflict with or violate any agreements between Clinic and any other party. Performance of the Services in a professional manner includes meeting the requirements for which Company is obligated to perform services for the patients, and failure to do so shall constitute a material breach of this Agreement. Clinic shall be solely responsible for the professional performance of the Services, and shall receive no assistance, direction, or control from Company. Clinic shall have sole discretion and control of Clinic services and the manner in which they are performed.
                    </li>

                    <li>
                    	Clinic shall and does hereby indemnify, defend, and hold harmless Company, and Company’s officers, directors, shareholders, agents, employees, and attorneys from and against any and all claims, demands, losses, costs, expenses, obligations, liabilities, damages, recoveries, and deficiencies, including interest, penalties, and reasonable attorney fees and costs, that Company may incur or suffer and that result from, or are related to any breach or failure of Clinic to perform any of the representations, warranties, covenants, or agreements contained in this Agreement.
                    </li>

                    <li>
                    	Clinic further agrees to maintain a policy of insurance in the minimum amount of $500,000 in general liability coverage, to cover any negligent acts committed by Clinic or Clinic employees or agents during the performance of the Services and in addition thereto shall maintain E&O policy at a minimum level of $500,000 and preferably at a level of $1,000,000/$3,000,000 claims made coverage.
                    </li>
                  </ol>
                  <h2 className="text-center">
                    INTELLECTUAL PROPERTY AND PROPRIETARY INFORMATION
                  </h2>
                  <ol start="1">
                    <li>
                    Clinic and Company are unaware of any particular designs, plans, specifications, prototypes, models, inventions and the like that will be part of the work environment between Clinic and Company.  However, to the extent that there are any such items, then the following paragraphs shall control.
                    </li>

                    <li>
                    	Clinic agrees that all designs, plans, reports, specifications, drawings, schematics, prototypes, models, inventions, and all other information and items made during the course of this Agreement and arising from the Services (“New Developments”) shall be and are hereby assigned to Company as its sole and exclusive property. On Company’s request, Clinic agrees to assist Company, at Company’s expense, to obtain patents or copyrights for such New Developments, including the disclosure of all pertinent information and data, the execution of all applications, specifications, oaths, and assignments, and all other instruments and papers that Company shall deem necessary to apply for and to assign or convey to Company, its successors and assigns or nominees, the sole and exclusive right, title, and interest in such New Developments. Clinic agrees to obtain or has obtained written assurances from its employees and contract personnel of their agreement to these terms with regard to New Developments.
                    </li>

                    <li>
                    	Clinic warrants that Clinic has good title to any New Developments, and the right to assign New Developments to Company free of any proprietary rights of any other party or any other encumbrance whatever.
                    </li>

                    <li>
                    The written, printed, graphic, or electronically recorded materials furnished by Company for use by Clinic are proprietary, confidential information and the property of Company (“Proprietary Information”). Proprietary Information also includes, but is not limited to, specific customer requirements, customer and potential customer lists, including information concerning Company’s employees, agents, or divisions, and pricing information.  Clinic will maintain in confidence and will not, directly or indirectly, disclose or use, either during or after the term of this Agreement, any Proprietary Information or confidential information or know-how belonging to Company, whether or not it is in written or permanent form, and regardless of whether it is marked or designated as “confidential” except to the extent necessary to perform the Services. On termination of the Services to the Company, or at the request of Company before termination, Clinic shall deliver to Company all material in Clinic’s possession relating to Company’s business. The obligations concerning Proprietary Information extend to information belonging to customers and suppliers of Company about whom Clinic may have gained knowledge as a result of performing the Services.
                    </li>

                    <li>
                    	Clinic shall not, during the term of this Agreement and for a period of two (2) years immediately following the termination of this Agreement, or any extension of it, for any reason, either directly or indirectly: (a) call on, solicit, or take away any of Company’s customers or potential customers about whom Clinic became aware as a result of Clinic Services to the Company, either for Clinic or for any other person or entity; or (b) solicit or take away or attempt to solicit or take away any of Company’s employees or contractors either for Clinic or for any other person or entity.
                    </li>
                  </ol>
                  <h2 className="text-center">MISCELLANEOUS PROVISIONS</h2>

                  <ol start="1">
                    <li>Time is of the essence in this contract.</li>

                    <li>
                    	Any notice, tender, delivery, or other communication pursuant to this contract shall be in writing and shall be deemed to be properly given if delivered, mailed, or sent by wire or other telegraphic communication as follows:
                      <p>
                        <strong>(a)</strong> If to Company:
                      </p>
                      <p>
                        Recover<br></br>
                        {siteDetail.address}
                        <br></br>
                        <a href={"mailto:" + siteDetail.email}>
                          {siteDetail.email}
                        </a>
                      </p>
                      <p>
                        With Notice to Company’s Attorney:<br></br>
                        Law Offices of Gregg Sultan<br></br>
                        1203 Crescent Heights Blvd<br></br>
                        Los Angeles, CA 90035.<br></br>
                        <a href="mailto:GreggSultanEsq@gmail.com">
                          GreggSultanEsq@gmail.com
                        </a>
                        <br></br>
                      </p>
                      <p>
                        <strong>(b)</strong> If to Clinic –In the event Company needs to contact Clinic, Company will use the contact information Clinic provides in the Recover website/platform. Clinic contact information must be up to date in Recover website/platform.  
                      </p>
                      <p>
                      Either party may change that party's address for these purposes by giving written notice of the change to the other party in the manner provided in this paragraph. If sent by mail, any notice, delivery, or other communication shall be effective or deemed to have been given 48 hours after it has been deposited in the U.S. mail, duly registered or certified, with postage prepaid, and addressed as set forth above. If sent by wire or other form of telegraphic communication, any notice, delivery, or other communication shall be effective or deemed to have been given eight hours after it has been deposited with the carrier, prepaid and addressed as set forth above.
                      </p>
                    </li>

                    <li>
                    	This contract and the attached exhibits constitute the entire agreement between the parties relating to services to be provided. Any amendment to this contract shall be of no force and effect unless it is in writing and signed by both parties.
                    </li>

                    <li>
                    If any action, proceeding, or arbitration arising out of or relating to this contract is commenced by either party to this contract as between the parties, the prevailing party shall be entitled to receive from the other party, in addition to any other relief that may be granted, the reasonable attorney's fees, costs, and expenses incurred in the action, proceeding, or arbitration by the prevailing party.
                    </li>

                    <li>
                    This Agreement and the Exhibit hereto, contain the entire agreement between the parties with respect to the matters covered herein. Both parties acknowledge that they are entering into this Agreement solely on the basis of the agreements and representations contained herein.  This Agreement may not be modified or amended except by a writing signed by both parties.  Any invoice or other document issued by Clinic is for administrative convenience only.   In the event of any conflict between this Agreement and any such invoice or Clinic-issued document, this Agreement shall prevail.
                    </li>

                    <li>
                    	This contract shall be binding on and inure to the benefit of the parties to this contract and their heirs, personal representatives, successors, and assigns, except as otherwise provided in this contract.
                    </li>

                    <li>
                    	This Agreement shall be governed by and construed in accordance with the laws of the State of California excluding its conflict of laws rules.  The parties consent to the jurisdiction of the state and federal courts of the County of San Diego California and waive any right to contest such jurisdiction.  Clinic hereby expressly agrees and acknowledges that an actual or threatened breach by Clinic of any of Clinic’s obligations hereunder could result in severe and irreparable injury to Company, which injury could not be adequately compensated by an award of money damages, and Clinic therefore agrees and acknowledges that Company shall be entitled to injunctive relief in the event of any such breach, or to enjoin or prevent such a breach, including, without limitation, an action for specific performance hereof and other equitable relief, and the parties hereby irrevocably consent to the issuance of any such injunction.  The parties further agree that no bond or surety shall be required in connection therewith.  Any such remedy shall not be deemed to be the exclusive remedy for Clinic’s breach of this Agreement, but shall be in addition to all other remedies available at law or in equity to Company.
                    </li>

                    <li>
                    The headings in this contract are inserted for convenience only. They do not constitute part of this contract and shall not be used in its construction.
                    </li>

                    <li>
                    The waiver by any party to this contract of a breach of any provision of this contract shall not be deemed a continuing waiver or a waiver of any subsequent breach of that or any other provision of this contract.
                    </li>

                    <li>
                    	Each party acknowledges that because of the logistics involved, that counterparts and electronic copies of this agreement are acceptable, vis-à-vis, signature as though originals.
                    </li>

                    <li>
                    	In the event that any phrase, clause, sentence, paragraph, section, article or other portion of this agreement shall become illegal, null or void, or against public policy, for any reason, or shall be held by any court of competent jurisdiction to be illegal, null or void, or against public policy, the remaining portions of this Agreement shall not be affected thereby and shall remain in full force and effect to the fullest extent permitted by law.
                    </li>

                    <li>
                    The expiration or termination of this Agreement shall not affect those provisions, and the rights and obligations therein, set forth in this Agreement which either: (a) by their terms state, or evidence the intent of the parties, that the provisions survive the expiration or termination of the Agreement; or (b) must survive to give effect to the provisions of this Agreement.
                    </li>

                    <li>
                    	This Agreement, and Clinic’s rights and obligations hereunder, are personal in nature and may not be assigned by Clinic.  Any assignment in violation of this Agreement shall be void.  This Agreement may be assigned by Company and shall inure to the benefit of the successors and assigns of Company, including, without limitation, an affiliate of Company to which this Agreement is assigned, or any other corporation or entity into which Company may be merged or with which it may be consolidated.
                    </li>
                  </ol>
                  <p>
                    IN WITNESS WHEREOF, the undersigned have executed this
                    Agreement as of the Effective Date
                  </p>
                  <strong>“COMPANY – RECOVER PHYSICAL THERAPY, INC”</strong>
                  <p>
                  BY: Ericka Craft Mizuta, DPT-Owner and Mary McLendon Owner 
                  </p>
                  <strong>
                  “CLINIC” – By Clicking You “agree” to this Agreement in Recover App Registration, you agree you have read and will abide by the terms and conditions set forth in this Agreement.{" "}
                  </strong>
                  <h2 className="text-center">
                    EXHIBIT “A”<br></br>Description of Services
                  </h2>
                  <p className="text-center">
                  To perform services on a project by project basis pertaining to healthcare services being rendered for patients referred by Company to Clinic.  Diagnosis, development of plan and execution all are solely and totally within the control of the Clinic on a project basis.  All projects that have been completed shall have treatment notes submitted from Clinic to Company.  Payment will be transferred to Clinic 12 hours after each treatment note is submitted. 
                  </p>
                </div>
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default withLocalize(ClinicTerm);
