import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';
import { providerListSpecific } from '../../Actions/User.action';
import $ from 'jquery';

const Therapist = ({ history }) => {

    const [allProvidersList,setAllProvidersList]= useState([])
    const [allProvidersCount,setAllProvidersCount]= useState(4)
    const [showMenu,setShowMenu]= useState("Show More")
    useEffect(() => {
        document.documentElement.scrollTop = 0;

    }, []);
    const getAllProviders = async () => {
        const response  = await providerListSpecific("Physical Therapist");
        setAllProvidersList(await response.data); 
    }
useEffect(()=>{
    getAllProviders();
})
const toggleAllList = () => {
    if(showMenu== "Show More")
    {
        setAllProvidersCount(allProvidersList.length)
        setShowMenu("Show Less")
    }
    else 
    {
        setAllProvidersCount(4)
        window.scrollTo({top:1500,left:0,behavior:"smooth"})
        setShowMenu("Show More")
    }

}
    return (
        <div className='women-health therapist'>
            {/* <MetaTags>
                <title>Women Health</title>
                <meta name="description" content="To access e-INSCRIPTION’s services through a website or mobile app, you need an account on our platform. To create an account you have to provide accurate info" />
                <meta name="keywords" content="LIVE online video streaming providers Indore, online video streaming providers, Online teaching in Indore, Online Training in Indore, Virtual classes in Indore " />
            </MetaTags> */}
            <section className='women-health-hero'>
                <div className="container">
                    <div className='women-health-hero-inner'>
                        <h1><span>Physical</span> Therapist</h1>
                        <p>Moving You Forward to a Pain- Free Life, One Step at a Time</p>
                    </div>
                    <a class='btn btn-primary' href="#">Learn More</a>
                </div>
            </section >
            <section className='our-mission'>
                <div className="container">
                    <div className="section_title">
                        <h2>Our Mission</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className='our-mission-inner'>
                        <div className="row">
                            <div className="col-md-6">
                                <img src='../static/img/our-mission.png' alt='' />
                            </div>
                            <div className="col-md-6">
                                <h3> Physical Therapist </h3>
                                <p>
                                    Recover is a telehealth platform that provides clients with quick and easy access to Healthcare Providers wherever and whenever they need it. Healthcare specialists may also use Recover to fill cancelations that may arise within their own practice or to build their practice.
                                    Our goal is to connect clients with world-class providers to regain optimal health.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className='our-service'>
                <div className="container">
                    <div className="section_title">
                        <h2 className=''>Our Services</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="our-service-container">
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Online appointment scheduling</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Telemedicine services</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Prescription refill requests</p>
                        </div>
                        <div className='our-service-card'>
                            <div className='service-circle'>
                                <img src='../static/img/fax.svg' alt='' />
                            </div>
                            <p>Patient portals</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='health-care-providers'>
                <div className="container">
                    <div className="section_title">
                        <h2>Healthcare Providers</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="health-care-providers-inner">
                    {allProvidersList?.map((single,index)=>{
                        if(index+1<=allProvidersCount){
                            return (
                                 <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                            <img
                              src={`${process.env.REACT_APP_STATIC_S3_URL + single.profileImg}`}
                              alt="No Profile Pic"
                              onError={(e) => {
                                e.target.src = "/static/img/providerimg.png"
                                e.target.style = ''
                              }}
                            />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Orthopedic PT</h4>
                                <Link class='btn btn-primary book-app' to="/provider-list">Book Appointmnet</Link>
                            </div>
                        </div>
                        )}
                        })}
                        {/* <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr01.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Orthopedic PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr02.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Sports PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr03.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Pediatric PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr04.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Geriatric PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr04.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Women’s Health PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr04.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Neurological PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div>
                        <div className="health-care-providers-card">
                            <div className="health-care-providers-card-img">
                                <img src='../static/img/dr04.svg' alt='' />
                            </div>
                            <div className="health-care-providers-card-content">
                                <h4>Oncology PT</h4>
                                <a class='btn btn-primary book-app' href="#">Book Appointmnet</a>
                            </div>
                        </div> */}
                    </div>
                    <div style={{display:"flex", justifyContent:"center", marginTop:"50px"}}>

               {allProvidersList.length>4 && <button className='btn btn-primary book-app' onClick={()=>{toggleAllList();}}>{showMenu}</button>}
                    </div>
                </div>
            </section>

            <section className='benefits'>
                <div className='container'>
                    <div className="section_title">
                        <h2>Benefits</h2>
                        <div className="decor2"></div>
                    </div>

                    <div className="benefits-row">
                        <div className="benefits-col">
                            <h3>Convenience</h3>
                            <p>Telehealth allows patients to receive physical therapy from the comfort of their own homes, without the need to travel to a clinic or hospital.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits.svg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="benefits-row row-inverse">
                        <div className="benefits-col">
                            <h3>Increased accessibility</h3>
                            <p>Telehealth can increase accessibility to physical therapy for patients who live in rural or remote areas, or who have difficulty traveling to in-person appointments.</p>
                        </div>
                        <div className="benefits-col">
                            <div className="benefits-col-img">
                                <img src='../static/img/benefits02.svg' alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='consulting-specialist'>
                <div className="container">
                    <div className="section_title">
                        <h2>Consulting Specialist</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="consulting-specialist-inner">
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/consulting01.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                <h3>Evaluate and Treat</h3>
                                {/* <p>Assess and evaluate the nutritional needs of clients</p> */}
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/consulting01.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                <h3>Personalized Treatment Plans</h3>
                                {/* <p>Develop personalized meal plans based on client’s health goals, medical conditions, and lifestyle.</p> */}
                            </div>
                        </div>
                        <div className="consulting-specialist-col">
                            <div className="consulting-specialist-ic">
                                <img src='../static/img/consulting01.svg' alt='' />
                            </div>
                            <div className="consulting-content">
                                <h3>Home Exercise Program</h3>
                                {/* <p>Provide education and counseling to help clients make healthier food choices and achieve their nutrition-related goals.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='faq-wrapper'>
                <div className="container">
                    <div className="section_title">
                        <h2>Frequently Asked Questions</h2>
                        <div className="decor2"></div>
                    </div>
                    <div className="faq-inner">
                        <div className="faq-col">
                            <div className="faq-img-wrapper">
                                <img src='../static/img/faq-img.svg' alt='' />
                            </div>
                        </div>
                        <div className="faq-col faq-content">
                            <div id="accordion">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                How can I schedule a telehealth appointment with a physical therapist?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body">
                                            <p>earch for a physical therapist on the Recover platform and request an appointment for the day and time you need. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                hat technology do I need for a telehealth appointment?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div className="card-body">
                                            You may use a laptop, PC, tablet, or phone with Google Chrome.  The device must have sufficient video and sound capabilities.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Will my insurance cover telehealth appointments with a nutritionist?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div className="card-body">
                                            Recover does bill insurance.  Clients may submit claims to their own insurance company for reimbursement.  The cash rate Recover charges per physical therapist will be listed during client booking.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                How do I access my medical records or test results through telehealth?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div className="card-body">
                                            ou will be able to view past notes in Appointment/Payment History. You may use your default print feature (Ctl +P) to print. You may also email <a href='mailto:info@recover.healthcare'>info@recover.healthcare</a> to obtain a copy of your note.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}
export default withLocalize(Therapist);