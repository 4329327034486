import React,{useState} from 'react';
import { withRouter } from 'react-router-dom';
import { useForm } from "react-hook-form";
import toastr from "../../../_Common/toaster";
import {forgotPasswordAction} from '../../Actions/User.action';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import './Forgot.css';

const ForgotPage = ({history, setIsSignInForm}) => {
	const [signInForm,setSignInForm] = useState({});
	const [signInErrors,setSignInErrors] = useState({});
	const { handleSubmit, register, errors } = useForm();
	const [spinner,setSpinner] = useState(false);

	const inputHandler = (e,field)=>{
		let loginFormData = signInForm;
		loginFormData['ForgotType'] = field;
		loginFormData[field] = e.target.value;
		setSignInForm(loginFormData);
	}

	const setForgotType = (e,field)=>{
		if(field==='email'){
			document.getElementById("ForgotTypePhone").readOnly = true;
			document.getElementById("ForgotTypeEmail").readOnly = false;
		}else if(field==='phone'){
			document.getElementById("ForgotTypePhone").readOnly = false;
			document.getElementById("ForgotTypeEmail").readOnly = true;
		}
	}

  	const forgorFormSubmit = async () =>{
	    if(signInForm.ForgotType===undefined){
	    	toastr.show({message: "Please enter email/phone.",intent: "danger"});
	    	return false
	    }
	    setSpinner(true);
		let res = await forgotPasswordAction(signInForm);
		if(res.success){
			setSpinner(false);
		    history.push('/reset-password');
			if(signInForm.ForgotType==='email'){
				toastr.show({message: "The One Time Passcode has been sent to your registered email address.",intent: "success"});
			}else{
				toastr.show({message: "The One Time Passcode has been sent to your registered phone number.",intent: "success"});
			}
		}else{
			setSpinner(false);
			if(res.err && res.err.length > 0){
				setSignInErrors(FormError(res.err));
			}else if(res.msg){
				toastr.show({message: res.msg,intent: "danger"});
			}
		}
  	}

	return (
	    <div className="form_modals singin_main">
	  		<div>
	      		<h5 className="modal-title">Forgot Password</h5>
	        	<form onSubmit={handleSubmit(forgorFormSubmit)}>
					<div className="form-group">
	                    <input type="email" name="email" id="ForgotTypeEmail" 
	                    	onClick={e=>setForgotType(e,'email')} onChange={e=>inputHandler(e,'email')} 
							className="form-control" placeholder="Email"
							ref={register({
								required: signInForm.ForgotType==='email'?'Required':'',
									pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: "Invalid email address"
								}
							})}
	                    />
	                    <div className="text-danger">{ShowFormErrors(errors,signInErrors,"email")}</div>
					</div>					
					<div className="form-group">
						<center>
							<h5 className="or-forg"> OR </h5>
						 </center>
					</div>
					<div className="form-group">
	                    <input type="text" name="phone" id="ForgotTypePhone" 
	                    	onClick={e=>setForgotType(e,'phone')} onChange={e=>inputHandler(e,'phone')} 
	                    	maxLength="10" className="form-control" placeholder='Phone'
	                        ref={register({
								required: signInForm.ForgotType==='phone'?'Required':'',
								pattern: {
									value:  /^[-+]?[0-9]+$/,
									message: "Please enter number"
								}
	                        })}
	                    />
	                    <div className="text-danger">{ShowFormErrors(errors,signInErrors,"phone")}</div>
					</div>
			        <div className="form-group">
			            <input type="submit" className="btn btn-primary" value="Submit" />
			        </div>
	        	</form>
	      	</div>
			<div className="spinner" style={{display:spinner?'block':'none'}}>
				<img src="/static/img/spinner.gif" alt='loader'/>
			</div>
	    </div>
    );
}
export default withRouter(ForgotPage);
