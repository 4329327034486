import React,{useState,useEffect} from 'react';
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import toastr from "../../../_Common/toaster";
import {userLogoutAction} from '../../Actions/User.action';
import { Dialog } from "@blueprintjs/core";
import SignUp from '../SignUp/SignUp';
import './HeaderPage.css';
import $ from 'jquery';
import AutoLogOff from './AutoLogOff';
import Authentication  from './Authentication';

const HeaderPage = (props) => {

  sessionStorage.setItem("currentUrl", props.location.pathname);
  const [isSignupForm,setIsSignupForm] = useState(false);
  const [homeScroll, setHomeScroll] = useState(true);


  const onLogout = async () =>{
    
    sessionStorage.setItem("isPopOpen", 'false');
    $('#navbarResponsive').removeClass("show");
    let res = await userLogoutAction();
    if(res.success){
      localStorage.clear();
      sessionStorage.clear();
      //setIslogedIn(false);
      toastr.show({message: 'Logout successfully',intent: "success"});
      props.history.push('/');
      setTimeout(function(){
          window.location = "";
      },2000);
          
    }else{
     if(res.msg){
        toastr.show({message: res.msg,intent: "danger"});
      }
    }
  }


  useEffect(()=>{
    
    $('.navbar-collapse .nav-link').on('click', function(){
      $('#navbarResponsive').removeClass("show");
    });
    window.addEventListener("scroll", function (event) {
      var scrollvalue = this.scrollY;
      if(scrollvalue > 50){
        setHomeScroll(false)
      }else{
        setHomeScroll(true)
      }
    });
   

  },[]);

  const changeMenuFunction = async (e, menuId, pname) =>{
    e.preventDefault();
    $('#navbarResponsive').removeClass("show");
    if(pname && pname!==''){
      props.history.push(pname);
      return false;
    }

    menuId = menuId?menuId:'home';
    if(window.location.pathname==='/'){
      $('html, body').animate({
         scrollTop: $('#'+menuId).offset().top-40
      }, 10);
    }else{
      props.history.push('/');
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $('#'+menuId).offset().top-40
        }, 10);
      },500);
    }
  }

  return (
    <header>
      <div className={[!homeScroll?'headerClassTwo':'', window.location.pathname==='/'?'home_header header_main':'header_main'].join(" ")}>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <Link to="#" className="navbar-brand js-scroll-trigger" onClick={e=>changeMenuFunction(e,'home')}>
              <img className="logo_white" src="../static/img/logo_white.png" alt="logo"/>
              <img className="logo_color1" src="../static/img/logo_color.png" alt="logo" />
            </Link>
            <button
              className="navbar-toggler navbar-toggler-right" type="button"
              data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
              aria-expanded="false" aria-label="Toggle navigation"><i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto" id='toplinks'>
                <li className="nav-item">
                  <Link to="#" className="nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'home')}>Home</Link></li>
                <li className="nav-item">
                  <Link to='#' className="nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'about')}>About</Link>
                </li>
                <li className="nav-item">
                  <Link to='#' className="nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'how')}>How Recover Works</Link>
                </li>
                {(localStorage.getItem("RecoverWebStatus")) && localStorage.getItem("userType")==='PATIENT' &&             
                  <li className="nav-item">
                    <Link to='/calendar' className="nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'how','/calendar')}>Book an Appointment</Link>
                  </li>
                }

                {(!localStorage.getItem("RecoverWebStatus")) &&            
                  <li className="nav-item">
                    <Link to='/provider-list' className="nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'how','/provider-list')}>Book an Appointment</Link>
                  </li>
                }
               
                {(localStorage.getItem("RecoverWebStatus")) &&              
                  <li className="nav-item">
                    <Link to='/profile' onClick={e=>changeMenuFunction(e,'how','/profile')} className="nav-link js-scroll-trigger">Account Information</Link>
                  </li>
                }
              </ul>
              <div className="hader_btns"> 
              <ul>
                  {(!localStorage.getItem("RecoverWebStatus")) &&
                    <li className="register_link">
                        <Link to="#" className="hbtn nav-link js-scroll-trigger" 
                          onClick={e=>setIsSignupForm(true)}><span>Register</span></Link>
                    </li>
                    
                  }  
                  {(!localStorage.getItem("RecoverWebStatus")) &&
                    <li className="login_link">
                        <Link to='#' className="hbtn nav-link js-scroll-trigger" onClick={e=>changeMenuFunction(e,'about')}><span>Login</span></Link>
                    </li>
                  }
                  {(localStorage.getItem("RecoverWebStatus")) &&
                    <li className="logout_link">
                        <Link to="#" className="hbtn nav-link js-scroll-trigger" onClick={e=>onLogout()}><span>Logout</span></Link>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Dialog
        className="form_designpage" onClose={e=>setIsSignupForm(false)}
        title="" canOutsideClickClose={false} isOpen={isSignupForm}>
          <SignUp history={props.history} setIsSignupForm={setIsSignupForm} />
      </Dialog>
      {localStorage.getItem("RecoverWebStatus") && 
        <AutoLogOff onLogout={onLogout}></AutoLogOff>
      } 
     {process.env.REACT_APP_AUTHENTICATION == 'true' && 
      <Authentication onLogout={onLogout}></Authentication>
     }
         
    </header>
  );
}
export default withLocalize(withRouter(HeaderPage));