import React from 'react';
import { withRouter,Link } from "react-router-dom";

const ThankyouClinic = ({history}) => {
  return (
        <div className='thankyou-bg' style={{width:"100%", height:"99vh" ,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div className='thankyou-popup'>
                <div className='container'>
                    <div className="row text-center">
                        <h4 style={{width:"100%"}} className="text-center">
                            Thank you.
                        </h4>
                    </div>
                    <div className="row ">
                        <div className="decor"></div>
                    </div>
                    <div className="row mt-3 mb-2">
                        <div className='col text-center'>
                            <h5>
                            You have registered successfully. We are verifying your details... Please wait for the administrator to approve your account, we will notify you.
                            </h5>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <div className="btn_section text-center">
                                <Link className="btn btn-primary js-scroll-trigger" to="/">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
  );
}
export default withRouter(ThankyouClinic);
