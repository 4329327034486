import React,{useState, useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import {BootstrapTable,TableHeaderColumn} from 'react-bootstrap-table';
import {getClinicPTListAction, clinicInActivePtAction} from '../../Actions/User.action';
import toastr from "../../../_Common/toaster";
import SideMenu from '../DashboardPage/SideMenu';
import { confirmAlert } from 'react-confirm-alert'; 
import MetaTags from 'react-meta-tags';
import BreadcrumbsComp from '../../../_Common/Breadcrumbs';

const ClinicPtPage = ({history}) => {
    
    const [dataList, setDataList] = useState([]);

    const getClinicPTListFunction = async()=>{
        let ptList = await getClinicPTListAction();    
        setDataList(ptList.data.data);
    }

    const onProfilePic=(cell,row, enumObject, index)=>{
        const intials = row.fullName.split(' ').map(name => name[0]).join('').toUpperCase();
        if(row.profileImg && row.profileImg!=''){
            return (<img className='profilepic' src={`${process.env.REACT_APP_STATIC_S3_URL+row.profileImg}`} alt={intials}/>);
        }else{
            return (<div className="profilepic"> {intials} </div>);
        }
    }   
    const onFullName=(cell,row, enumObject, index)=>{
        const fullName = row.fullName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
            return letter.toUpperCase();
        });
        return (<Link to={{pathname:"/edit-clinic-pt", state:{cptId: row._id}}}>{fullName}</Link>);
    }    
    const onPhoneNo=(cell,row, enumObject, index)=>{
        return row.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }    
    const onAction=(cell,row, enumObject, index)=>{
        return (row.enabled==='true'?<Link to="#" onClick={e=>ptInActive(e, row._id)}>Inactivate</Link>:'Contact Admin');
    }
    const ptInActive = async(e,id)=>{
        e.preventDefault();
        if(id && id!==''){
          confirmAlert({
            title: 'Change Status',
            message: 'Are you sure you want to Inactivate?',
            buttons: [{
                label: 'Yes',
                onClick: async() => {               
                    let res = await clinicInActivePtAction({ptId:id});
                    if(res.success===true){
                        getClinicPTListFunction();
                        toastr.show({message: "Provider inactivated successfully. Please contact to administrator to activate Provider.",intent: "success"});
                    }else if(res.success===false){
                        toastr.show({message: res.msg,intent: "danger"});
                    }
                }
              },
              {label: 'No',}
            ]
          });
        }
    }

    const onAvailability=(cell,row, enumObject, index)=>{
        
        if(row.enabled && row.enabled==='true'){           
            return (<Link to={{pathname:"/my-availability", state:{ptId: row._id, name: row.fullName, email:row.email, timeZone:row.timeZone, insuranceExpDate:row.insuranceExpDate, licenseExpDate:row.licenseExpDate}}}>Mark Availability </Link>);
        }else{
            return (<Link to="#" onClick={e=>toastr.show({message: 'Can not set availability as Provider is inactivated, please contact Admin to activate!',intent: "danger"})}>Mark Availability </Link>);
        }
    }

	useEffect(()=>{
		document.documentElement.scrollTop = 0;
		getClinicPTListFunction();
	},[]);

	return (
  	<div>
    <MetaTags className="bg-rr">
        <title>List Of Providers</title>
        <meta name="description" content="Recover" />
        <meta name="keywords" content="Recover" />
    </MetaTags>
    <section className="dash_section pad-reggis clinic_pt_page">
        <BreadcrumbsComp bcChanges={[{path: '/clinic-pt', breadcrumb: 'List Of Providers'}]} />
        
        <div className="container">
            <div className="row">
                <div className="col-md-3 left_dashboard scrollbar2 leftContainer">
                  <SideMenu/>
                </div>
                <div className="col-md-9 right_dashboard scrollbar3 rightContainer">
    	            <div className="content-setion">
                        <div className="m-25">
                            <h3>List Of Providers</h3>
                        </div>
                        <BootstrapTable deleteRow={false} data={dataList}
                            search={true} scrollTop={'Bottom'} pagination={false}>
                            <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                            
                            <TableHeaderColumn width='90' dataField='' dataFormat={onProfilePic} dataSort={true}>Profile Pic</TableHeaderColumn>
                            
                            <TableHeaderColumn width='200' dataField='fullName' dataFormat={onFullName} dataSort={true} searchable={true}>Provider Name</TableHeaderColumn>                        
                            <TableHeaderColumn width='200' dataField='email' dataSort={true} searchable={true}>Email</TableHeaderColumn>

                            <TableHeaderColumn width='110' dataField='phone' dataFormat={onPhoneNo} dataSort={true} searchable={true}>Phone No.</TableHeaderColumn>

                            <TableHeaderColumn width='100' dataField='fullName' dataFormat={onAvailability} dataSort={true} searchable={true}>Mark Availability</TableHeaderColumn>
      
                            <TableHeaderColumn width='110' dataField='enabled' dataFormat={onAction} dataSort={true} searchable={true}>Action</TableHeaderColumn>
                        </BootstrapTable>   
    	    		
    	    		</div>
    	    	</div>
        	</div>
        </div>
    </section>
   	</div>
	);
}
export default withLocalize(ClinicPtPage);