import React,{useState,useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { useSelector,useDispatch } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import MetaTags from 'react-meta-tags';
import {isValidURL} from '../../../_Constants/app.constants';
import renderHTML from 'react-render-html';


const SessionPage = ({history}) => {
  return (
     
    <section className="pad-reggis session_page">
      <div className="container-fluid">
        <div className="row session_wrap">
            <div className="col-lg-12 session_top">
                <div className="session_topinner row">
                 <div className="col-lg-3">
                   <label>Client Name</label>
                   <p>Tony Gaetano</p>
                </div>
                <div className="col-lg-3">
                   <label>Appoinment Date</label>
                   <p>10/08/2020</p>
                </div>
                <div className="col-lg-3">
                   <label>Session Duration</label>
                   <p>08:00 AM - 08:30 AM</p>
                </div>
                 <div className="col-lg-3">
                   <label>DOB(Age)</label>
                   <p>24 Years</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 session_left">
             
              <div className="sessionvideo_sec">
                <div className="sessionvideo">
                   <video>
                      <source src="/static/img/demo.mp4" type="video/mp4" />
                    </video>
                    <div className="videocontrols">
                       <button id="timer" className="btn btn-primary">9:30</button>

                       <button className="btn btn-primary btn-round">
                        <img src="/static/img/video-player.png"/>
                      </button>
                      <button className="btn btn-primary btn-round">
                        <img src="/static/img/unmute.png"/>
                      </button>
                      <button className="btn btn-primary">End Session</button>
                      <button id="full-screen" className="btn btn-primary btn-round">
                        <img src="/static/img/full-screen.png"/>
                      </button>

                    </div>
                </div>
                <div className="video_btn_session">
                  <a className="btn btn-primary" href="#">Accept to continue next session</a>
                </div>
              </div>
          
            </div>
            <div className="col-lg-4 session_right">
               <div className="session_rightinner">
                   <div className="Session_btns">
                       <div className="Session_btns">
                           <button className="btn btn-primary">Client Documents</button>
                       </div>
                        <div className="Session_btns">
                           <button className="btn btn-primary">Previous Provider Notes</button>
                       </div>
                        <div className="Session_btns">
                           <button className="btn btn-primary">Submit Notes</button>
                       </div>

                         <div className="popup" id="patientdocumentmodl">
                            <div className="modal-dialog">
                              <div className="modal-content">

                                <div className="modal-header">
                                  <h4 className="modal-title">Client Documents</h4>
                                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                    <div className="document_sec">
                                      <h5> Client Documents </h5>
                                      <div className="row" id="patientDocs">
                                         <div className="col-lg-2" key="0">
                                            <div className="document_single">
                                               <div className="document_single_img"><a href="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/anr3j5apm8n7jil6t8l3l.png" target="_blank"><img src="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/anr3j5apm8n7jil6t8l3l.png" alt="img"/></a></div>
                                               Leg
                                            </div>
                                         </div>
                                         <div className="col-lg-2" key="1">
                                            <div className="document_single">
                                               <div className="document_single_img"><a href="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/71fpc7mq51jifitse7rpt.png" target="_blank"><img src="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/71fpc7mq51jifitse7rpt.png" alt="img"/></a></div>
                                               Neck
                                            </div>
                                         </div>
                                         <div className="col-lg-2" key="2">
                                            <div className="document_single">
                                               <div className="document_single_img"><a href="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/5jgaf9okikkc0a52f6259.jpg" target="_blank"><img src="https://recoverimage.s3-us-west-1.amazonaws.com/dev/paDocs/5jgaf9okikkc0a52f6259.jpg" alt="img"/></a></div>
                                               Gluteal
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                   <div className="row medicalhistory">
                                      <h5> Medical History</h5>
                                      <div className="card-body medicalHistory_wrapinner">
                                         <div className="checkwrap_sec">
                                            <div className="row">
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Asthma, Bronchitis, or Emphysema</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_1" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_1" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Severe or Frequent Headache</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_2" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_2" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Coronary Heart Disease of Angina</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_3" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_3" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Vision or Hearing Difficulties</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_4" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_4" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Shortness of Breath/Chest Pain</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_5" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_5" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Numbness or Tingling</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_6" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_6" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>High Blood Pressure</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_7" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_7" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Dizziness of Fainting</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_8" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_8" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Heart Attack or Heart Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_9" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_9" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Bowel or Bladder Problems</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_10" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_10" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Stroke/TIA</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_11" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_11" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Weakness</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_12" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_12" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Congestive Heart Failure</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_13" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_13" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Hernia</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_14" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_14" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Blood Clot/Emboli</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_15" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_15" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Weight Loss/Energy Loss</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_16" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_16" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Epilepsy/Seizures</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_17" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_17" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Varicose Veins</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_18" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_18" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Thyroid Disease</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_19" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_19" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Allergies</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_20" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_20" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Anemia</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_21" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_21" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Pins or Metal Implants</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_22" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_22" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Infectious Disease</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_23" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_23" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Joint Replacement Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_24" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_24" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Diabetes</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_25" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_25" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Neck Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_26" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_26" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Cancer or Chemo/Radiation</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_27" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_27" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Shoulder Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_28" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_28" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Arthritis</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_29" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_29" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Elbow/Hand Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_30" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_30" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Osteoporosis</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_31" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_31" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Knee Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_32" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_32" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Sleeping Problems</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_33" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_33" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Back Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_34" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_34" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Gout</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_35" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_35" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Ankle/Foot Injury/Surgery</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_36" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_36" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Emotional/psychological Problems</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_37" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_37" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Are you pregnant?</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_38" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_38" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Do you have a pacemaker?</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_39" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_39" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                               <div className="col-lg-6 col-md-12">
                                                  <div className="checkrow">
                                                     <span>Do you use tobacco?</span>
                                                     <div className="checkrowcol"><label><input type="radio" name="demo_40" value="yes" disabled=""/><span>Yes</span></label><label><input type="radio" name="demo_40" value="no" disabled=""/><span>No</span></label></div>
                                                  </div>
                                               </div>
                                            </div>
                                         </div>
                                         <div className="row medica_btm_sec">
                                            <div className="col-md-12">
                                               <div className="form-group"><label>Medication with Dosage and Frequency</label><textarea rows="4" className="form-control" name="medicationDosage" disabled="">Medication with Dosage and Frequency
                                                  </textarea>
                                               </div>
                                            </div>
                                            <div className="col-md-12">
                                               <div className="form-group"><label>Any Other Comment</label><textarea rows="4" className="form-control" name="otherComment" disabled="">Comment</textarea></div>
                                            </div>
                                         </div>
                                         <div className="row">
                                            <div className="col-md-6 Patient_sign_wrap">
                                               <div className="form-group">
                                                  <label>Client/Guardian Signature </label>
                                                  <div className="Patient_sign">suresh</div>
                                               </div>
                                            </div>
                                            <div className="col-md-6 Patient_sign_wrap">
                                               <div className="form-group">
                                                  <label>Date</label>
                                                  <div className="sub_date">October 20th, 2020</div>
                                               </div>
                                            </div>
                                         </div>
                                         <div className="spinner"><img src="/static/img/spinner.gif" alt="loader"/></div>
                                      </div>
                                   </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>


                           <div className="modal" id="myModal">
                            <div className="modal-dialog">
                              <div className="modal-content">
                              
                                <div className="modal-header">
                                  <h4 className="modal-title">Client Documents</h4>
                                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                  Modal body..
                                </div>
                                
                              </div>
                            </div>
                          </div>


                           <div className="popup" id="submitnotes">
                            <div className="modal-dialog">
                              <div className="modal-content">
                              
                                <div className="modal-header">
                                  <h4 className="modal-title">Client Documents</h4>
                                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>

                                <div className="modal-body">
                                  <div className="session_note_wrap">
                                   <div className="note_dropdown">
                                      <label for="noteselect" className="notedropdesign">
                                         <select id="noteselect" className="mdb-select md-form form-control">
                                            <option value="" disabled="">Choose your option</option>
                                            <option value="">Initial evaluation</option>
                                            <option value="">Progress note</option>
                                            <option value="">Daily note</option>
                                            <option value="">Other Provider</option>
                                            <option value="">No show note</option>
                                         </select>
                                      </label>
                                   </div>
                                   <div className="session_noteform_wrap">
                                      <div className="session_note_ttl">Initial evaluation</div>
                                      <div className="form-group"><label>Subjective</label><textarea placeholder="Enter your Text Here" rows={6} className="form-control"></textarea></div>
                                      <div className="pain_history_sec">
                                         <h3>Pain History</h3>
                                         <div className="pain_history_single">
                                            <div className="row">
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Area</label>
                                                     <label for="area1" className="notedropdesign">
                                                        <select id="area1" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">Area</option>
                                                           <option value="">C - Spine</option>
                                                           <option value="">C - Spine</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                            </div>
                                            <div className="row">
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Current</label>
                                                     <label for="area2" className="notedropdesign">
                                                        <select id="area2" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Best</label>
                                                     <label for="area3" className="notedropdesign">
                                                        <select id="area3" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Worst</label>
                                                     <label for="area4" className="notedropdesign">
                                                        <select id="area4" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group"><button className="btn add_painhistroy">+</button></div>
                                               </div>
                                            </div>
                                         </div>
                                         <div className="pain_history_single">
                                            <div className="row">
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Area</label>
                                                     <label for="area5" className="notedropdesign">
                                                        <select id="area5" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">Area</option>
                                                           <option value="">C - Spine</option>
                                                           <option value="">C - Spine</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                            </div>
                                            <div className="row">
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Current</label>
                                                     <label for="area6" className="notedropdesign">
                                                        <select id="area6" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Best</label>
                                                     <label for="area7" className="notedropdesign">
                                                        <select id="area7" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group">
                                                     <label>Worst</label>
                                                     <label for="area8" className="notedropdesign">
                                                        <select id="area8" className="mdb-select md-form form-control">
                                                           <option value="" disabled="">1/10</option>
                                                           <option value="">1</option>
                                                           <option value="">2</option>
                                                           <option value="">3</option>
                                                           <option value="">4</option>
                                                           <option value="">5</option>
                                                           <option value="">6</option>
                                                           <option value="">7</option>
                                                           <option value="">8</option>
                                                           <option value="">9</option>
                                                           <option value="">10</option>
                                                        </select>
                                                     </label>
                                                  </div>
                                               </div>
                                               <div className="col-md-3 col-sm-12">
                                                  <div className="form-group"><button className="btn add_painhistroy">+</button><button className="btn remove_painhistroy">-</button></div>
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                      <div className="form-group inline_field observation_field"><label className="label">Observation</label><textarea placeholder="Enter your Text Here" rows={6} className="form-control"></textarea></div>
                                      <div className="form-group inline_field bodypart_field">
                                         <label className="label">Body Part</label>
                                         <label for="bodypart" className="notedropdesign">
                                            <select id="bodypart" className="mdb-select md-form form-control">
                                               <option value="" disabled="">Choose Body Part</option>
                                               <option value="">Ankel</option>
                                            </select>
                                         </label>
                                         <button className="btn noteform_btn">Add</button>
                                      </div>
                                      <div className="arom_sec">
                                         <div className="arom_topsec">
                                            <table border="0">
                                               <tr>
                                                  <th>Arom</th>
                                                  <th>Right Side</th>
                                                  <th>left Side</th>
                                               </tr>
                                               <tr>
                                                  <td>Inversion</td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                               </tr>
                                               <tr>
                                                  <td>Eversion</td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                               </tr>
                                               <tr>
                                                  <td>Plantar Flexion</td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                               </tr>
                                               <tr>
                                                  <td>Dorsi Flexion</td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                                  <td>
                                                     <select className="form-control">
                                                        <option>25%</option>
                                                        <option>50%</option>
                                                        <option>75%</option>
                                                        <option>100%</option>
                                                     </select>
                                                  </td>
                                               </tr>
                                            </table>
                                         </div>
                                         <div className="arom_btmsec row">
                                            <div className="col-md-6 col-sm-12">
                                               <div className="form-group text-center"><label>Muscle Testing</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                               <div className="form-group text-center"><label>Special Testing</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                            </div>
                                         </div>
                                         <div className="arom_buttonsec text-right"><button className="btn noteform_btn">Remove</button></div>
                                      </div>
                                      <div className="form-group"><label className="label">Assessment</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                      <div className="form-group"><label className="label">Problem List</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                      <div className="form-group"><label className="label">Goals</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                      <div className="form-group"><label className="label">Treatment Plan</label><textarea placeholder="Enter your Text Here" className="form-control"></textarea></div>
                                      <div className="form-group"><label className="label">Initial Treatment</label><textarea  placeholder="Enter your Text Here"  className="form-control "></textarea></div>
                                      <div className="form-group text-right edit_save_btns"><button className="btn noteform_btn">Save</button><button className="btn noteform_btn">Edit</button></div>
                                      <div className="form-group text-right Submit_bnt"><button className="btn noteform_btn">Submit</button></div>
                                   </div>
                                </div>
                                </div>

                              </div>
                            </div>
                          </div>
                   </div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
}
export default withLocalize(withRouter(SessionPage));



