import React,{useEffect} from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from "react-router-dom";
import {siteDetail, appConstants} from '../_Constants/app.constants';

const AccessDenied = (access=null) => {
    useEffect(()=>{
        document.documentElement.scrollTop = 0;
    },[]);

    return (
        <section className="about_page pad-reggis page404">
            <div className="container">
                <div className="row white_box">
                    <div className="col-lg-12 col-sm-12">
                        <div className="section_title page_ttl text-center">
                            <img src="/static/img/404icon.png" alt="404icon"/>
                            <h3>404</h3>
                            <div className="decor2"></div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12">
                        <p><h1>Page not Found</h1></p>
                        <p>We're sorry the page you requested could not be found. Please go back
                        to the home page or contact us at <a href={'mailto:'+siteDetail.email}>{siteDetail.email}</a>
                        </p>
                    </div>
                    <div className="col-lg-12">
                        <Link to='/' className="btn btn-primary">Back to Home</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default withLocalize(AccessDenied);
