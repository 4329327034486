import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { withLocalize } from "react-localize-redux";
import { siteDetail } from "../../../_Constants/app.constants";
import "./FooterPage.css";
import moment from "moment";

const FooterPage = ({ history }) => {
  return (
    <footer>
      <section className="contact-section bg-black">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <img src="/static/img/logo_color.png" className="mb-4" alt="" />
              <p>
              Your Trusted Telehealth Platform for Seamless Client-Provider Connections. Our mission at Recover is to bring clients and healthcare specialists together with unmatched convenience and accessibility. Moreover, Recover extends its value to clinics and individual providers, offering a powerful tool to help minimize the impact of revenue loss resulting from unexpected cancellations within their practice.
              </p>
              <h5>Stay Connected</h5>
              <div className="social d-flex justify-content-left">
                <a className="mx-2" target="_blank" href={siteDetail.facebook}>
                  <i className="fab fa-facebook"></i>
                </a>
                <a className="mx-2" target="_blank" href={siteDetail.twitter}>
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="mx-2" target="_blank" href={siteDetail.instagram}>
                  <i className="fab fa-instagram"></i>
                </a>
                <a className="mx-2" target="_blank" href={siteDetail.tiktok}>
                  {" "}
                  <img
                    src="/static/img/tiktok.svg"
                    alt="tiktok.svg"
                    width="23"
                    height="17"
                  />
                </a>
                <a className="mx-2" target="_blank" href={siteDetail.youtube}>
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-0 footer_contact_sec">
              <h4>Support</h4>
              <p>
                <span className="ficon">
                  <img src="/static/img/phone.svg" alt="phone.svg" />
                </span>
                <a href={"tel:+1" + siteDetail.phone} title={siteDetail.phone}>
                  {siteDetail.phone}
                </a>
              </p>
              <p>
                <span className="ficon">
                  <img src="/static/img/fax.svg" alt="fax.svg" />
                </span>
                <a href={"fax:+1" + siteDetail.fax} title={siteDetail.fax}>
                  {siteDetail.fax}
                </a>
              </p>
              <p>
                <span className="ficon">
                  <img src="/static/img/email.svg" alt="email.svg" />
                </span>
                <a href={"mailto:" + siteDetail.email} title={siteDetail.email}>
                  {siteDetail.email}
                </a>
              </p>
              <p>
                <span className="ficon">
                  <img src="/static/img/address.svg" alt="address.svg" />
                </span>
                <span className="w-f-addred">{siteDetail.address}</span>
              </p>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <h4>Quick Links</h4>
              <div className="row">
                <div className="col-md-6">
                  <ul>
                    <li>
                      <Link to="/founding-stories">Founding Stroies</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/Provider-Spotlight">Provider Spotlight</Link>
                    </li>
                    <li>
                      <Link to="/faqs">Help & FAQs</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-condition">Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to="/informed-consent">Informed Consent</Link>
                    </li>
                    {/* <li><Link >Provider Spotlight</Link></li> */}
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <Link to="/womens-health">Women's Health</Link>
                    </li>
                    <li>
                      <Link to="/life-coach">Life Coach</Link>
                    </li>
                    <li>
                      <Link to="/therapist">Therapist</Link>
                    </li>
                    <li>
                      <Link to="/dietitian">Dietition</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col footer text-center">
                  <hr />
                  <div className="row">
                    <div className="col-md-6 text-left">
                      <p>
                        Copyright © {moment().format("YYYY")} Recover Inc. All
                        Right Reserved.
                      </p>
                    </div>
                    <div className="col-md-6 text-right nonn">
                    <p> Developed and managed by <a href="https://synergytop.com/portfolio-item/synergytop-develops-a-multi-functional-web-app-for-a-healthcare-platform/" target="_blank">SynergyTop LLC</a> </p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
export default withLocalize(withRouter(FooterPage));
