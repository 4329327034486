//const moment = require('moment');
const moment = require('moment-timezone');

export const userTypes = {
    ADMIN:"ADMIN",
    STUDENT:"STUDENT",
    USER:"USER"
}

export const isValidURL= (str) => {
    var url = str;
    var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (pattern.test(url)) {
      return url;
    }
    return url = `${process.env.REACT_APP_STATIC_S3_URL}${str}`;
}

export const checkCardImg = (brand) => {
  if(brand==="visa"){
    return('/static/cardImage/visa.png');
  }else if(brand==="mastercard"){
    return('/static/cardImage/mastercard.png');
  }else if(brand==="discover"){
    return('/static/cardImage/discover.png');
  }else if(brand==="diners"){
    return('/static/cardImage/diners.png');
  }else if(brand==="amex" || brand==="americanExpress"){
    return('/static/cardImage/american-express.png');
  }else if(brand==="jcb"){
    return('/static/cardImage/jcb.png');
  }else{
    return(false);
  }
}

export const checkExtFunction = (data) => {
  var fileFullName = `${process.env.REACT_APP_STATIC_S3_URL+data}`
  var imageExt = ['bmp','jpg','png','jpeg'];
  if(data && data!==''&& data!==undefined){
    if(/[^.]+$/.exec(data)[0]==='pdf'){
      return ('<a href="'+fileFullName+'" target="_blank"><img src="/static/img/pdfIcon.png" alt="img"/></a>')
    }else if(/[^.]+$/.exec(data)[0]==='doc' || /[^.]+$/.exec(data)[0]==='docx'){
      return ('<a href="'+fileFullName+'" target="_blank"><img src="/static/img/docIcon.png" alt="img"/></a>')
    }else if(imageExt.indexOf(/[^.]+$/.exec(data)[0]) > -1){
      return ('<a href="'+fileFullName+'" target="_blank"><img src="'+fileFullName+'" alt="img"/></a>')
    }else{
      return ('<a href="'+fileFullName+'" target="_blank"><img src="/static/img/file.png" alt="img"/></a>')
    }
  }
}

export const appConstants = {
  GoogleAPI: "AIzaSyDe3attsz2w4Br8mGQG9zKTYP0DoXmEh7s", //use for find current lat-long

  appLink: "https://play.google.com/store/apps/details?id=com.einscriptions",

  //DATEFORMAT: "MMM d, yyyy",
  DATEFORMAT: "MMMM Do, yyyy",

  TIMEFORMAT: "hh:mm a",
  DateFormatNew: "MMMM do, yyyy",

  courseType:[
      { "label": "Text+Image", "value": 101 },
      { "label": "Video", "value": 102 },
      { "label": "Audio", "value": 103 },
      { "label": "Pdf", "value": 104 },
      { "label": "Quiz/Test", "value": 105 }
  ],
  durationType:[
      { "label": "Infinite", "value": "infinite" },
      { "label": "Minute", "value": "minute" },
      { "label": "Hour", "value": "hour" },
      { "label": "Day", "value": "day" },
      { "label": "Week", "value": "week" },
      { "label": "Month", "value": "month" }
  ],

  permissionType:[
      { "label": "Yes", "value": 1 },
      { "label": "No", "value": 0 }
  ],

  statusType:[
      { "label": "Enable", "value": 1 },
      { "label": "Disable", "value": 0 }
  ],

  answerType:[
      { "label": "True or False", "value": 101 },
      { "label": "Multiple Choice", "value": 102 },
      { "label": "Single Choice", "value": 103 },
      { "label": "Fill in the Blank", "value": 106 },
  ],
      // { "label": "Sort Answers", "value": 104 },
      // { "label": "Descriptive Answer", "value": 107 }

  unitType:[
      { "label": "Image", "value": 101 },
      { "label": "Video", "value": 102 },
      { "label": "Audio", "value": 103 },
      { "label": "Pdf", "value": 104 }
  ],
  fileType:[
      { "label": "Image{Jpeg,Png)", "value": 101 },
      { "label": "File(Pdf,Doc,Excel)", "value": 102 },
      { "label": "Video(Mp4)", "value": 103 },
      { "label": "Audio(Mp3)", "value": 104 },
  ],
   scheduleType:[
      { "label": "Webinar", "value": 101 },
      { "label": "Broadcast", "value": 102 },
      { "label": "Podcast", "value": 103 }
  ],

  EmptyMsg: "No data found!",
  DateFormat:"dd-mm-yyyy",

  orderStatus:[
      { "label": "On hold", "value": 101 },
      { "label": "Completed", "value": 104 },
      { "label": "Canceled", "value": 105 }
  ],

  orderStatusView:{
       101: "On hold",
       102: "Pending Payment",
       103: "Processing",
       104: "Completed",
       105: "Canceled",
       106: "Refunded",
       107: "Failed",
  },

  aboutStatus:[
      { "label": "Friends", "value": "friends" },
      { "label": "Social Site", "value": "social_site" },
      { "label": "News Paper", "value": "news_paper" },
      { "label": "Online Ads", "value": "online_ads" }
  ],

  loginType:{
       "institute": "INSTITUTE",
        "admin": "ADMIN",
  },
  //timeZone:(localStorage.getItem("userType") && localStorage.getItem("userType") =='PT'?"America/Los_Angeles":"Asia/Calcutta"),
  //timeZone:"America/Los_Angeles",
  timeZone:(localStorage.getItem("timeZone") && localStorage.getItem("timeZone") !=''?localStorage.getItem("timeZone"):moment.tz.guess()),
}

export const siteDetail = {
  address: "5694 Mission Center Rd #602-121  San Diego, CA 92108 ",
  email: "info@recover.healthcare",
  phone: "619-488-6918",
  fax:'619-488-6913',
  facebook: 'https://www.facebook.com/RecoverHealthandWellness',
  instagram: 'https://www.instagram.com/recoverhealthandwellness/',
  youtube: 'https://www.youtube.com/channel/UCGhAgF4QPq5yOG-c_6kEnOg',
  tiktok: 'https://www.tiktok.com/@recoverwellness',
  twitter: 'https://twitter.com/Pt20Recover',
  domainName: "recover.healthcare"
}

export const getusDate= (str) => {
//console.log('str', str)
  const today = moment();
  return moment(str, "YYYY-MM-DD").format('MMMM Do, YYYY');
}

export const getDate= (toFormat='', dateTime='', dateFormat='', timzone='America/Los_Angeles') => {

    if(dateTime !='' && dateFormat != '' && toFormat != ''){

        return moment(dateTime, dateFormat).tz(timzone).format(toFormat);

    }else if(dateTime !='' && toFormat != '' && dateFormat == ''){

        return moment(dateTime).tz(timzone).format(toFormat);

    }else if(dateTime =='' && toFormat != '' && dateFormat == ''){

        return moment().tz(timzone).format(toFormat);
        
    }else{

        return moment().tz(timzone);      
    }
}

  export const getTimeZone= () => {
    
  //console.log(moment().format('MMM, DD YYYY h:mm:ss A'));
      var cutoffString = '2020-12-14 05:21:00'; // in utc
      var utcCutoff = moment.utc(cutoffString, 'YYYY-MM-DD HH:mm:ss');
      var displayCutoff = utcCutoff.clone().tz('America/Los_Angeles');

      console.log('utcCutoff:', utcCutoff.format('YYYYMMDD hh:mm:ssa Z')); // => utcCutoff: 20170421 04:30:00pm +00:00
      console.log('displayCutoff:', displayCutoff.format('YYYYMMDD hh:mm:ssa Z')); // => displayCutoff: 20170421 12:30:00pm -04:00
      return '';
  
  }

  export const toUTC= (date) => {    
        let timeZone = (localStorage.getItem("timeZone") && localStorage.getItem("timeZone") !=''?localStorage.getItem("timeZone"):moment.tz.guess());
        var a = moment.tz(date, timeZone);
        return a.utc().format(); 
        
    }
  export const utcToZone= (date, format) => {
    var utcCutoff = moment.utc(date, 'YYYY-MM-DD HH:mm:ss');
    var displayCutoff = utcCutoff.clone().tz(appConstants.timeZone);
    return displayCutoff.format(format);
  }

  export const getLocalDate= (date) => {        
        let d = new Date(date);
        let dd = d.getDate();
        dd = dd>9?dd:'0'+dd;
        let mm = (d.getMonth()+1);
        mm = mm>9?mm:'0'+mm;
        date = d.getFullYear()+'-'+mm+'-'+dd;
    return date; 
      
  }

  export const setTimeZone= (data=[]) => {  
    //console.log(data);
    let timezone = moment.tz.guess();  
    let abbr = '';
    if(data && data.timeZone && data.timeZone.utc && data.timeZone.utc[0]){
        timezone = data.timeZone.utc[0];
        abbr = data.timeZone.abbr;
    }
    localStorage.setItem("timeZone",timezone);
    localStorage.setItem("abbr",abbr);
    moment.tz.setDefault(timezone);
  }

  export const setSessionValue= (data=[]) => {  
    
    if(data && data!=='' && data!==undefined){
      if(data._id && data.userType && data.token && data.email){
        localStorage.setItem("_id",data._id);
        localStorage.setItem("userType",data.userType);
        localStorage.setItem("jwtToken",data.token);
        localStorage.setItem("email",data.email);
      }
      localStorage.setItem("firstName",data.firstName);
      localStorage.setItem("lastName",data.lastName);
      localStorage.setItem("fullName",data.fullName);
      localStorage.setItem("phone",data.phone);
      localStorage.setItem("clinicId",data.clinicNumberName?data.clinicNumberName:'');
      localStorage.setItem("profileImg",data.profileImg);
      localStorage.setItem("RecoverWebStatus",true);

      localStorage.setItem("address",data.address);
      localStorage.setItem("address1",data.address1);
      localStorage.setItem("aptStreet",data.aptStreet);
      localStorage.setItem("city",data.city);
      localStorage.setItem("state",data.state);
      localStorage.setItem("zipCode",data.zipCode);

      if(data.userType==='PT'){
        localStorage.setItem("insuranceExpDate",data.insuranceExpDate?data.insuranceExpDate:'');
        localStorage.setItem("licenseExpDate",data.licenseExpDate?data.licenseExpDate:'');
        localStorage.setItem("dob",data.dob?data.dob:'');
      }else if(data.userType==='CLINIC'){
        localStorage.setItem("insuranceExpDate",data.insuranceExpDate?data.insuranceExpDate:'');
        localStorage.setItem("licenseExpDate",'');
      }
      setTimeZone(data);
    }
  }

  // Simple App that loads a picture.
export const App = (onstart, onload) => {
  onstart();  

  const img = document.createElement("img");
  img.src = "https://picsum.photos/640/480";
  img.className = "hide";
  img.onload = () => {
    img.className = "";
    onload();
  };

  //const app = document.getElementById("app");
  //app.innerHTML = "";
  //app.append(h1);
  //app.append(img);
}

let isSlow;
let loaded;

export const loadApp = () => {
  App(
    () => {
      loaded = false;
      isSlow = setTimeout(() => {
        // Show "Slow Internet Connection." message.
        document.body.className = "slow";
        console.debug("slow");
      }, 1500); // Intentionally a low number for testing. Try some different values here, or play with different Internet Throttling setting.
    },
    () => {
      loaded = true;
      clearTimeout(isSlow);
      setTimeout(() => { document.body.className = ""; }, 1000); // Hide message after 1s.
      console.debug("loaded");
  });
};


export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export const  getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const  deleteCookie = (cname) => {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}