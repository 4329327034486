
import React,{useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from "react-hook-form";
import { withLocalize } from 'react-localize-redux';
import {FormError,ShowFormErrors} from "../../../_Common/formError";
import toastr from "../../../_Common/toaster";
import {userRegistrationAction} from '../../Actions/User.action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {appConstants,getLocalDate,toUTC} from '../../../_Constants/app.constants';
import {timezones} from '../../../_Constants/timezones';
import GeoAddressComponent from '../../../_Common/GeoAddressManage';
import NumberFormat from 'react-number-format';
import $ from 'jquery';

const ClinicPage = ({history}) => {
  const dispatch = useDispatch();
  const [signupForm,setSignupForm] = useState({"time_zone":"(UTC-08:00) Pacific Time (US & Canada)"});
  const [insuranceDate, setInsuranceDate] = useState();
  const [signupErrors,setSignupErrors] = useState({});
  const {register, handleSubmit, setValue, errors, setError, reset } = useForm();
  const [spinner,setSpinner] = useState(false);
  const [valueReset,setValueReset] = useState(true);

  const inputHandler = (e,field)=>{
    let signupFormData = signupForm;
    if(field === 'phone' && e.target.value.replace(/[-]/g, '').trim().length === 10){
      $('input[name="phone"]').removeClass("requiredClass");
    }
    if(field == 'time_zone'){
      let timezoneValue = timezones.filter(
          function(timezones){return timezones.text == e.target.value}
      );      
      signupFormData['timeZone'] = JSON.stringify(timezoneValue[0]);
    }
    signupFormData[field] = e.target.value;
    setSignupForm({...signupForm,...signupFormData});
  }

  const checkboxHandler = (e,field)=>{
    let signupFormData = signupForm;
    signupFormData[field] = e.target.checked===true?true:false;
    setSignupForm(signupFormData);
  }

  const fileChangedHandler=(event, elename)=>{
    event.preventDefault();
    let signupFormData = signupForm;
    let file = event.target.files[0];
    if(!file && file===undefined){
      signupFormData[elename+'Preview'] = '';
      signupFormData[elename] = '';
      setSignupForm({...signupForm,...signupFormData});
      return false;
    }
    var fileName = (file && file.name?file.name:'');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
    if (extensions == 'bmp' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
        signupFormData[elename+'Preview'] = URL.createObjectURL(event.target.files[0]);
        signupFormData[elename] = event.target.files[0];
        setSignupForm({...signupForm,...signupFormData});
    }else if(extensions == 'docx'|| extensions == 'doc'){
        signupFormData[elename+'Preview'] = '/static/img/docIcon.png';
        signupFormData[elename] = event.target.files[0];
        setSignupForm({...signupForm,...signupFormData});
    }else if(extensions == 'pdf'){
        signupFormData[elename+'Preview'] = '/static/img/pdfIcon.png';
        signupFormData[elename] = event.target.files[0];
        setSignupForm({...signupForm,...signupFormData});
    }else {
      let err = [{"msg": "Extensions doesn't match","param": elename}];
      setSignupErrors(FormError(err));
    }
  }

  const onFormSubmit = async () =>{
    setSpinner(true);
    signupForm['userType'] = 'CLINIC';
    signupForm['insuranceExpDate']=insuranceDate?toUTC(getLocalDate(insuranceDate)):'';
    signupForm['phone'] = signupForm['phone'].replace(/\D/g,'');
    signupForm['phoneCode'] = "+1"; 
    signupForm['timeZone'] = (signupForm['timeZone']);
    let res = {};
    let formErrors = {};
    res = await userRegistrationAction(signupForm);
    if(res.err && res.err.length > 0){
      formErrors = FormError(res.err);
    }
    setSignupErrors(formErrors);
    if(res.success){
      setSpinner(false);
      document.getElementById("resetForm").reset();
      setSignupForm('');
      setInsuranceDate('');
			window.location = "thank-you-clinic";
    }else if(res.success===false){
      setSpinner(false);
      toastr.show({message: res.msg,intent: "danger"});
    }
  }

  const setResetForm = (e)=>{
    reset();
    setSignupForm({});
    setInsuranceDate();
    setValueReset(false);
    setTimeout(function() {
      setValueReset(true)
    }, 1000);
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="form_modals register_clinic_form">
            <form onSubmit={handleSubmit(onFormSubmit)} id='resetForm'>
              <div className="row">
                <div className="col-lg-12">
                  <div className="col section_title text-center">
                    <h3>Clinic  Registration</h3>
                    <div className="decor"></div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Clinic Name*</label>
                  <div className="form-group">
                    <input onChange={e=>inputHandler(e,'clinicName')} name="clinicName" type="text" 
                      className="form-control" placeholder="Clinic Name "
                      ref={register({
                        required: 'Required'
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"clinicName")}</div>
                  </div>
                </div>                
                <div className="col-lg-6">
                  <label>Clinic Contact(Name of Person)*</label>
                  <div className="form-group">
                    <input onChange={e=>inputHandler(e,'clinicContact')} name="clinicContact" type="text" 
                      className="form-control" placeholder="Clinic Contact (Name of Person)"
                      ref={register({
                        required: 'Required'
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"clinicContact")}</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Phone Number*</label>
                  <div className="form-group phone_field">
                    {/* <select className="form-control" name="phoneCode">
                      <option value="+1" defaultValue>+1</option>
                    </select> */}
                    <NumberFormat
                      format="###-###-####"
                      name="phone"
                      //pattern="[0-9,-]{12}"
                      className="form-control"
                      placeholder="Phone Number Here"
                      value={signupForm.phone?signupForm.phone:''}
                      onChange={e =>{ inputHandler(e, 'phone'); setValue("phone", e.target.value);}}
                      ref={register(!signupForm.phone?{name: "phone"}:'', 
                        {required: 'Required',
                        pattern: {value: /^(?=.*\d)[\d-]{12}$/, message: "Phone number should be 10 digit number."}
                      } )}
                    />
                    {!signupForm.phone &&
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                    }
                    {signupForm.phone && signupForm.phone.replace(/[-]/g, '').trim().length!==10 && 
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>
                    }
                    {/*<input name="phone" onChange={e=>inputHandler(e,'phone')} type="text" maxLength="12" 
                      className="form-control" placeholder='Phone' 
                      value={signupForm && signupForm.phone?signupForm.phone.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3'):''}
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value:  /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
                          message: "Phone number should be 10 digit number."
                        }
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"phone")}</div>*/}
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Email*</label>
                  <div className="form-group">
                    <input onChange={e=>inputHandler(e,'email')} name="email" type="email" 
                      className="form-control" placeholder="Email"
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Please enter a valid email address."
                        }
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"email")}</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Password*</label>
                  <div className="form-group">
                  <a title=' Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character'>

                    <input type="password" onChange={e=>inputHandler(e,'password')} name="password" 
                      className="form-control" placeholder="Password"
                      ref={register({
                        required: 'Required',
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,15}$/,
                          message: "Minimum 6 and maximum 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                        }
                      })}
                    /> </a>
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"password")}</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Confirm Password*</label>
                  <div className="form-group">
                    <input type="password" onChange={e=>inputHandler(e,'cPassword')} name="cPassword" 
                      className="form-control" placeholder="Confirm Password"
                      ref={register({ 
                        required: 'Required',
                        validate: (value) => value === signupForm.password || "Passwords don't match.",
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"cPassword")}</div>
                  </div>
                </div>
                
                <GeoAddressComponent
                  history={history}
                  register={register}
                  errors={errors}
                  formData={signupForm}
                  inputHandler={inputHandler}
                  signupErrors={signupErrors}
                  valueReset={valueReset}
                />

                <div className="col-lg-12 docheading">
                  <h4>W9 with TIN</h4> 
                </div>
                <div className="col-lg-6">
                  <label>Tax Identification Number*</label>
                  <div className="form-group">
                    <input type="text" onChange={e=>inputHandler(e,'w9WithEIN')} name="w9WithEIN" 
                      className="form-control" placeholder="Tax Identification Number"
                      ref={register({
                        required: 'Required'
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"w9WithEIN")}</div>
                  </div>
                </div>
                <div className="col-lg-6 uploadfilesec">
                    <label>Upload W9 Doc*</label>
                    <div className="form-group">
                      <input type="file" name="w9WithEINDoc" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" 
                        onChange={(e)=>fileChangedHandler(e, "w9WithEINDoc")}
                        ref={register({
                          required: 'Required'
                        })} 
                      />
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"w9WithEINDoc")}</div>
                    </div>
                    { signupForm.w9WithEINDocPreview && 
                      <div className="fileDocs"><img src={signupForm.w9WithEINDocPreview} alt="img"/></div>
                    }
                </div>
                <div className="col-lg-12 docheading">
                  <h4>Articles of Incorporation</h4> 
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Upload Articles of Incorporation Doc*</label>
                      <div className="form-group">
                        <input type="file" name="npiDoc" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" 
                          onChange={(e)=>fileChangedHandler(e, "npiDoc")}
                          ref={register({
                            // required: 'Required'
                          })} 
                        />
                        <div className="text-danger">{ShowFormErrors(errors,signupErrors,"npiDoc")}</div>
                      </div>
                      { signupForm.npiDocPreview && 
                        <div className="fileDocs"><img src={signupForm.npiDocPreview} alt="img"/></div>
                      }
                    
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 docheading"> 
                  <h4>Proof of Practice Malpractice Insurance</h4> 
                </div>
                <div className="col-lg-4">
                  <label> Policy Number*</label>
                  <div className="form-group">
                    <input type="text" onChange={e=>inputHandler(e,'insuranceNumber')} name="insuranceNumber" 
                      className="form-control" placeholder="Policy Number "
                      ref={register({
                        required: 'Required'
                      })}
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceNumber")}</div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <label>Expiration Date*</label>
                  <div className="form-group">
                    <DatePicker
                      minDate={new Date()}
                      name='insuranceExpDate'
                      className="form-control"
                      selected={insuranceDate}
                      dateFormat={appConstants.DateFormatNew}
                      placeholderText="Click to select Expiration Date"
                      onChange={date => { setInsuranceDate(date); setValue("insuranceExpDate", date);}}
                      ref={register({name: "insuranceExpDate"}, {required: 'Required'} )} 
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                    />
                    {!insuranceDate &&
                      (<div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceExpDate")}</div>)
                    }
                  </div>
                </div>
                <div className="col-lg-4 uploadfilesec">
                  <label>Upload Insurance Doc*</label>
                  <div className="form-group">
                    <input type="file" name="insuranceDoc" accept=".bmp,.jpg,.png,.jpeg,.pdf,.docx,.doc" 
                      onChange={(e)=>fileChangedHandler(e, "insuranceDoc")}
                      ref={register({
                        required: 'Required'
                      })} 
                    />
                    <div className="text-danger">{ShowFormErrors(errors,signupErrors,"insuranceDoc")}</div>
                  </div>
                  { signupForm.insuranceDocPreview && 
                    <div className="fileDocs"><img src={signupForm.insuranceDocPreview} alt="img"/></div>
                  }
                </div>

                <div className="vbyop">
                  <div className="col-lg-12">
                    <div className="checkbbox">
                      <label htmlFor="termsConditions">
                        <input onChange={e=>checkboxHandler(e,'termsConditions')} id="termsConditions" type="checkbox" defaultChecked="" name='termsConditions'
                           ref={register({
                            required: 'Required'
                          })} 
                         />
                        <p>Terms & Conditions (<a href="/Clinic-Term" target="_blank">Click here</a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"termsConditions")}</div>
                    </div>
                    <div className="checkbbox">
                      <label htmlFor="informedConsent">
                        <input onChange={e=>checkboxHandler(e,'informedConsent')} id="informedConsent" type="checkbox" defaultChecked="" name='informedConsent'
                          ref={register({
                            required: 'Required'
                          })} 
                        />
                        <p>Informed Consent (<a href="/informed-consent" target="_blank">Click here </a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"informedConsent")}</div>
                    </div>
                    <div className="checkbbox">
                      <label htmlFor="privacyPolicy">
                        <input onChange={e=>checkboxHandler(e,'privacyPolicy')} id="privacyPolicy" type="checkbox" defaultChecked="" name='privacyPolicy'
                          ref={register({
                            required: 'Required'
                          })} 
                        />
                        <p>Privacy Policy (<a href="/privacy-policy" target="_blank">Click here </a> to read)</p>
                      </label>
                      <div className="text-danger">{ShowFormErrors(errors,signupErrors,"privacyPolicy")}</div>
                    </div>                    
                   
                  </div>
                  <div className="col-lg-6 t-pp">
                    <input type="reset" onClick={e=>setResetForm(e)} className="btn btn-primary rt" value="Clear" />
                    <input type="submit" className="btn btn-primary" value="Submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="spinner" style={{display:spinner?'block':'none'}}>
        <img src="/static/img/spinner.gif" alt='loader'/>
      </div>
    </div>
  );
}

export default withLocalize(ClinicPage);