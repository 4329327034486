import React from 'react';
import  { BrowserRouter as Router, Route, Switch }  from 'react-router-dom';
import { LocalizeProvider } from "react-localize-redux";
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import {routerMiddleware} from 'react-router-redux';
import history from './history';
import reducer from '../Reducers'
import HomePage from '../../FrontEnd/Components/HomePage/HomePage';
import HeaderPage from '../../FrontEnd/Components/HeaderPage/HeaderPage.js';
import FooterPage from '../../FrontEnd/Components/FooterPage/FooterPage.js';

/*--- Recover ---*/
import Register from '../../FrontEnd/Components/Register/index.js';
import ResetPassword from '../../FrontEnd/Components/Forgot/ResetPassword';
import ChangePassword from '../../FrontEnd/Components/DashboardPage/ChangePassword/index';
import Profile from '../../FrontEnd/Components/DashboardPage/Profile/index';

//--PT
import MyAvailability from '../../FrontEnd/Components/PT/MyAvailability';
import AvailabilityCalendar from '../../FrontEnd/Components/Calendar/AvailabilityCalendar';

//--Patient
import PTList from '../../FrontEnd/Components/Patient/PTList';
import RequestForAppointment from '../../FrontEnd/Components/Patient/RequestForAppointment';

import FutureAppointmentList from '../../FrontEnd/views/appointment/FutureAppointmentList';
import AppointmentHistory from '../../FrontEnd/views/appointment/AppointmentHistory';
import PaymentHistory from '../../FrontEnd/views/appointment/PaymentHistory';
import Session from '../../FrontEnd/views/Session/Session';
import PreviousNotesDetail from '../../FrontEnd/views/Session/PreviousNotesDetail';

//--Clinic
import AddClinicPT from '../../FrontEnd/Components/Clinic/AddClinicPT';
import EditClinicPT from '../../FrontEnd/Components/Clinic/EditClinicPT';
import ClinicPT from '../../FrontEnd/Components/Clinic/ClinicPT';

import BlogPage from '../../FrontEnd/Components/SitePages/BlogPage';
import FamoseProvider from '../../FrontEnd/Components/SitePages/FamoseProvider';
import FindSpecialist from '../../FrontEnd/Components/SitePages/FindSpecialist';

import ThankyouClinic from '../../FrontEnd/Components/SitePages/ThankyouClinic';
import ThankyouPatient from '../../FrontEnd/Components/SitePages/ThankyouPatient';
import ThankyouPt from '../../FrontEnd/Components/SitePages/ThankyouPt';

import Blogdetail from '../../FrontEnd/Components/SitePages/Blogdetail';
import FeedbackDetail from '../../FrontEnd/Components/SitePages/FeedbackDetail';
import FAQSPage from '../../FrontEnd/Components/SitePages/FAQSPage';
import PrivacyPolicyPage from '../../FrontEnd/Components/SitePages/PrivacyPolicyPage';
import ClientPrivacy from '../../FrontEnd/Components/SitePages/ClientPrivacy';
import ProviderTerm from '../../FrontEnd/Components/SitePages/ProviderTerm';
import ClinicTerm from '../../FrontEnd/Components/SitePages/ClinicTerm';

import TermsandConditionPage from '../../FrontEnd/Components/SitePages/TermsandConditionPage';
import FoundingStoriesPage from '../../FrontEnd/Components/SitePages/FoundingStoriesPage';
import InformedConsentPage from '../../FrontEnd/Components/SitePages/InformedConsentPage';
import AboutUsPage from '../../FrontEnd/Components/SitePages/AboutUsPage';
import HowRecoverWorksPage from '../../FrontEnd/Components/SitePages/HowRecoverWorksPage';
import SessionPage from '../../FrontEnd/Components/SessionPages/index';
// import otherProviderNote from '../../FrontEnd/Components/SessionPages/otherProviderNote';
//import SessionPage from '../../FrontEnd/views/Session/NotesTemplate';otherProviderNote

import WomensHealth from "../Components/SitePages/WomensHealth"
import Dietitian from "../Components/SitePages/Dietitian"
import Therapist from "../Components/SitePages/Therapist"
import LifeCoach from "../Components/SitePages/LifeCoach"

import Checkout from '../../FrontEnd/Components/Stripe/Checkout';
import Invoice from '../../FrontEnd/Components/Stripe/Invoice';
import AccountCreate from '../../FrontEnd/Components/Stripe/AccountCreate';
import NotFoundPage from '../../_Common/accessDenied';
  
import { Helmet } from "react-helmet";
 //let data = (localStorage.getItem("permissions"))?atob(localStorage.getItem("permissions")):'';
 //let permission = (data!=='' && data!=='undefined')?JSON.parse(data):[];
 export const makeMainRoutes = () => {
    const historyMiddleware = routerMiddleware(history);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        composeEnhancers(
            applyMiddleware(historyMiddleware, thunk)
        )
    )

    return (
        <Provider store={store}>
            <Router location={history.location} history={history}>
                <div className="routesBox">
                    <LocalizeProvider>
                        <HeaderPage  />
                            <Switch>
                                <Route exact path="/" render={(props) =><>  <Helmet>
                                <title>Find Top Doctors in California | Recover.Healthcare</title>
                                <meta name="description" content="Get all type of Specialized Medical Care from Top Doctors in California at Recover.Healthcare Book your appointment now!" />  
                                </Helmet> <HomePage  {...props} /></>} />
                                <Route exact path="/register" render={(props) => <><Helmet></Helmet> <Register  {...props} /></>} />
                                <Route exact path="/reset-password/" render={(props) => <><Helmet></Helmet> <ResetPassword  {...props} /></>} />
                                <Route exact path="/reset-password/:code" render={(props) => <><Helmet></Helmet> <ResetPassword  {...props} /></>} />

                                <Route exact path="/change-password" render={(props) => <><Helmet></Helmet> <ChangePassword  {...props} /></>} />
                                <Route exact path="/profile" render={(props) => <><Helmet></Helmet> <Profile  {...props} /></>} />
                                <Route exact path="/my-availability" render={(props) => <><Helmet></Helmet> <MyAvailability  {...props} /></>} />

                                <Route exact path="/calendar" render={(props) => <AvailabilityCalendar  {...props} />} />
                                
                                <Route exact path="/provider-list" render={(props) => <PTList  {...props} />} />
                                <Route exact path="/RequestForAppointment" render={(props) => <RequestForAppointment  {...props} />} />
                                <Route exact path="/future-appointments" render={(props) => <FutureAppointmentList  {...props} />} />
                                <Route exact path="/appointment-history" render={(props) => <AppointmentHistory  {...props} />} />
                                <Route exact path="/payment-history" render={(props) => <PaymentHistory  {...props} />} />
                                <Route exact path="/session" render={(props) => <Session  {...props} />} />
                                <Route exact path="/notes-detail/:id" render={(props) => <PreviousNotesDetail  {...props} />} />

                                <Route exact path="/clinic-pt" render={(props) => <ClinicPT  {...props} />} />
                                <Route exact path="/add-clinic-pt" render={(props) => <AddClinicPT  {...props} />} />
                                <Route exact path="/edit-clinic-pt" render={(props) => <EditClinicPT  {...props} />} />

                                <Route exact path="/blog" render={(props) => <BlogPage  {...props} />} />
                                <Route exact path="/Provider-Spotlight" render={(props) => <FamoseProvider  {...props} />} />
                                <Route exact path="/FindSpecialist" render={(props) => <FindSpecialist  {...props} />} />

                                <Route exact path="/thank-you-clinic" render={(props) =>  <><Helmet></Helmet> <ThankyouClinic  {...props} /></>} />
                                <Route exact path="/thank-you-patient" render={(props) =>  <><Helmet></Helmet> <ThankyouPatient  {...props} /></>} />
                                <Route exact path="/thank-you-pt" render={(props) =>  <><Helmet></Helmet> <ThankyouPt  {...props} /></>} />


                                <Route exact path="/blogs/:title" render={(props) => <Blogdetail  {...props} />} />
                                {/* <Route exact path="/blogs/" render={(props) => <Blogdetail  {...props} />} /> */}
                                <Route exact path="/feedback-detail" render={(props) => <FeedbackDetail  {...props} />} />

                                <Route exact path="/faqs" render={(props) => <>  <Helmet>
                                <title>Frequently Asked Questions (FAQs) - Recover.Healthcare</title> <meta name="description" content="Find answers to common questions about healthcare services at Recover.Healthcare. Get the information you need for peace of mind." />  </Helmet> <FAQSPage  {...props} /></>} />

                                <Route exact path="/privacy-policy" render={(props) =>  <><Helmet></Helmet> <PrivacyPolicyPage  {...props} /></>} />
                                <Route exact path="/Client-Privacy" render={(props) => <ClientPrivacy  {...props} />} />
                                <Route exact path="/Provider-Term" render={(props) => <ProviderTerm  {...props} />} />
                                <Route exact path="/Clinic-Term" render={(props) => <ClinicTerm  {...props} />} />

                                <Route exact path="/terms-condition" render={(props) =><>  <Helmet>
                                <title>Terms and Conditions of Recover.Healthcare</title> <meta name="description" content="Explore the Terms and Conditions of Recover.Healthcare. Understand our policies and guidelines to ensure a smooth and secure healthcare experience." />  </Helmet> <TermsandConditionPage  {...props} /></>} />
                                <Route exact path="/terms-condition/:id" render={(props) =><>  <Helmet>
                                <title>Terms and Conditions of Recover.Healthcare</title> <meta name="description" content="Explore the Terms and Conditions of Recover.Healthcare. Understand our policies and guidelines to ensure a smooth and secure healthcare experience." />  </Helmet> <TermsandConditionPage  {...props} /></>} />
      

                                <Route exact path="/founding-stories" render={(props) => <>  <Helmet>
                                <title>Inspiring Founding Stories of Recover.Healthcare</title></Helmet><FoundingStoriesPage  {...props} /></>} />
                                <Route exact path="/informed-consent" render={(props) => <InformedConsentPage  {...props} />} />
                                <Route exact path="/about-us" render={(props) => <AboutUsPage  {...props} />} />
                                <Route exact path="/how-recover-works" render={(props) =>  <><Helmet></Helmet> <HowRecoverWorksPage  {...props} /></>} /> 
                                <Route exact path="/session-page" render={(props) => <SessionPage  {...props} />} /> 
                                {/* <Route exact path="/otherProviderNote" render={(props) => <otherProviderNote  {...props} />} />  */}

                                <Route exact path="/dietitian" render={(props) =><>  <Helmet>
                                <title>Find Your Perfect Dietitian in California - Recover.Healthcare</title>
                                <meta name="description" content="Unlock the path to a healthier you with personalized nutrition guidance. Connect with our skilled dietitians for tailored plans for sustainable wellness. Start your journey to better health now!" />  
                                </Helmet><Dietitian  {...props} /></>} />
                                <Route exact path="/womens-health" render={(props) =><>  <Helmet>
                                <title>Women's Health | Women's Healthcare Center California - Recover.Healthcare</title>
                                <meta name="description" content="Discover personalized women's healthcare solutions at Recover.Healthcare. Register today on our user-friendly web app to connect with experienced specialists dedicated to your well-being!" />  
                                </Helmet> <WomensHealth  {...props} /></>} />
                                <Route exact path="/therapist" render={(props) =><>  <Helmet>
                                <title>Transform Your Life with California's Physical Therapists | Recover.Healthcare</title>
                                <meta name="description" content="Unlock a pain-free, healthier life with experienced physical therapists in California. Restore vitality and well-being for a brighter future." />  
                                </Helmet> <Therapist  {...props} /></>} />
                                <Route exact path="/life-coach" render={(props) =><>  <Helmet>
                                <title>Find The Best Life Coach in California - Recover.Healthcare</title>
                                <meta name="description" content="Transform your life with expert guidance. Empower yourself to overcome challenges, set goals, and achieve lasting success. Start your journey today!" />  
                                </Helmet><LifeCoach  {...props} /></>} />


                                <Route exact path="/checkout" render={(props) => <Checkout  {...props} />} />
                                <Route exact path="/invoice" render={(props) => <Invoice  {...props} />} />
                                <Route exact path="/account-create" render={(props) => <AccountCreate  {...props} />} />
                            
                                <Route component={NotFoundPage} />
                            </Switch>
                        <FooterPage />
                    </LocalizeProvider>
                </div>
            </Router>
        </Provider>
    );
}
