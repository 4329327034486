import React, { Component, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import history from "./history";
import ReactGA from "react-ga4";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login/Login"));

// const TRACKING_ID = "G-9EY21BBVF2";
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

class App extends Component {


  
  render() {

    React.useEffect(() => {
 
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
  } , []);
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
