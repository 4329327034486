import {getRequest,postRequest} from "../../_Api/helper";

export const changePasswordAction = (data) => {
    return postRequest('changePassword',data).then(res=>{return res.data});
}

export const getClinicListAction = (data) => {
    return getRequest('admin/clinicList',data).then(res=>{return res.data});
}

export const userStatusAction = (data) => {
    return getRequest('admin/userStatusAction',data).then(res=>{return res.data});
}

export const specializationStatus = (data) => {
    return postRequest('admin/specializationStatus',data).then(res=>{return res.data});
}
export const addSpecialization = (data) => {
    return postRequest('admin/addSpecialization',data).then(res=>{return res.data});
}

export const masterDeleteApiAction = (data) => {
    return getRequest('admin/masterDeleteApi',data).then(res=>{return res.data});
}

export const deleteSpecialization = (data) => {
    return getRequest('admin/deleteSpecialization',data).then(res=>{return res.data});
}


//Blog action define start
export const getBlogListAction = (data) => {
    return getRequest('admin/getBlogList',data).then(res=>{return res.data});
}

export const addEditBlogAction = (data) => {
    return postRequest('admin/addEditBlog',data).then(res=>{return res.data});
}

export const getBlogDetailAction = (data) => {
    return getRequest('admin/getBlogDetail',data).then(res=>{return res.data});
}


export const deleteBlogAction = (data) => {
    return getRequest('admin/deleteBlog',data).then(res=>{return res.data});
}
//Blog action define end

//famouse provider 
export const adminFamousProviderList = (data) => {
    return postRequest('admin/adminFamousProviderList',data).then(res=>{return res.data});
}

export const updateFamousProvider = (data) => {
    return postRequest('admin/updateFamousProvider',data).then(res=>{return res.data});
}
export const requestAppointment = (data) => {
    return postRequest('admin/reqestAppointmentList',data).then(res=>{return res.data});
}

export const getFamousProviderDetail = (data) => {
    return getRequest('getFamousProviderDetail',data).then(res=>{return res.data});
}
export const addFamousProvider = (data) => {
    return postRequest('admin/addFamousProvider',data).then(res=>{return res.data});
}
export const deleteFamousProvider = (data) => {
    return getRequest('admin/deleteFamousProvider',data).then(res=>{return res.data});
}


//Feedback action define start
export const getFeedbackListAction = (data) => {
    return getRequest('admin/getFeedbackList',data).then(res=>{return res.data});
}

export const addEditFeedbackAction = (data) => {
    return postRequest('admin/addEditFeedback',data).then(res=>{return res.data});
}

export const getFeedbackDetailAction = (data) => {
    return getRequest('admin/getFeedbackDetail',data).then(res=>{return res.data});
}

export const updateSpecialization = (data) => {
    return postRequest('admin/updateSpecialization',data).then(res=>{return res.data});
}

export const getSpecilizationDetail = (data) => {
    return getRequest('admin/getSpecilizationDetail',data).then(res=>{return res.data});
}

export const specializationList = (data) => {
    return postRequest('admin/specializationList',data).then(res=>{return res.data});
}



export const deleteFeedbackAction = (data) => {
    return getRequest('admin/deleteFeedback',data).then(res=>{return res.data});
}
//Feedback action define end


//setPriceforEntities action define start
export const setPriceforEntitiesAction = (data) => {
    return postRequest('admin/addSettingData',data).then(res=>{return res.data});
}

export const getSetPriceforEntitiesAction = (data) => {
    return getRequest('admin/getSettingData',data).then(res=>{return res.data});
}

export const addSocialMediaDataAction = (data) => {
    return postRequest('admin/addSocialMediaData',data).then(res=>{return res.data});
}

export const getSocialMediaDataAction = (data) => {
    return getRequest('admin/getSocialMediaData',data).then(res=>{return res.data});
}


//AppointmentHistory
export const getAppointmentListAction = (data) => {
    return postRequest('admin/appointmentList',data).then(res=>{return res.data});
}
//PaymentHistory
export const getPaymentListAction = (data) => {
    return getRequest('admin/paymentList',data).then(res=>{return res.data});
}
export const paymentTransactionsList = (data) => {
    return getRequest('admin/paymentTransactionsList',data).then(res=>{return res.data});
}
export const getUserDetailAction = (data) => {
    return getRequest('admin/getUserDetail',data).then(res=>{return res.data});
}
export const updateEmail48ActiveStatus = (data) => {
    return getRequest('admin/updateEmail48ActiveStatus',data).then(res=>{return res.data});
}
